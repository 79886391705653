


/* Variables
================================== */
/* Tables
================================== */
.Rtable {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
.Rtable-cell {
  box-sizing: border-box;
  -webkit-box-flex: 1;
          flex-grow: 1;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  background: rgba(112, 128, 144, 0.2);
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
}
/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell {
  width: 50%;
}
.Rtable--3cols > .Rtable-cell {
  width: 33.33%;
}
.Rtable--4cols > .Rtable-cell {
  width: 25%;
}
.Rtable--5cols > .Rtable-cell {
  width: 20%;
}
.Rtable--6cols > .Rtable-cell {
  width: 16.6%;
}
/* Page styling
================================== */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMZhKg.ttf) format('truetype');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XbMZhKg.ttf) format('truetype');
}

strong {
  color: #434d57;
}
/* Apply styles
================================== */
.Rtable {
  position: relative;
  top: 3px;
  left: 3px;
}
.Rtable-cell {
  margin: -3px 0 0 -3px;
  background-color: white;
  border-color: #e2e6e9;
}
/* Cell styles
================================== */
.Rtable-cell--dark {
  background-color: slategrey;
  border-color: #5a6673;
  color: white;
}
.Rtable-cell--dark > h1,
.Rtable-cell--dark > h2,
.Rtable-cell--dark > h3,
.Rtable-cell--dark > h4,
.Rtable-cell--dark > h5,
.Rtable-cell--dark > h6 {
  color: white;
}
.Rtable-cell--medium {
  background-color: #b8c0c8;
  border-color: #a9b3bc;
}
.Rtable-cell--light {
  background-color: white;
  border-color: #e2e6e9;
}
.Rtable-cell--highlight {
  background-color: lightgreen;
  border-color: #64e764;
}
.Rtable-cell--alert {
  background-color: darkorange;
  border-color: #cc7000;
  color: white;
}
.Rtable-cell--alert > h1,
.Rtable-cell--alert > h2,
.Rtable-cell--alert > h3,
.Rtable-cell--alert > h4,
.Rtable-cell--alert > h5,
.Rtable-cell--alert > h6 {
  color: white;
}
.Rtable-cell--head {
  background-color: slategrey;
  border-color: #5a6673;
  color: white;
}
.Rtable-cell--head > h1,
.Rtable-cell--head > h2,
.Rtable-cell--head > h3,
.Rtable-cell--head > h4,
.Rtable-cell--head > h5,
.Rtable-cell--head > h6 {
  color: white;
}
.Rtable-cell--foot {
  background-color: #b8c0c8;
  border-color: #a9b3bc;
}
/* Responsive
==================================== */
@media all and (max-width: 500px) {
  .Rtable--collapse {
    display: block;
  }
  .Rtable--collapse > .Rtable-cell {
    width: 100% !important;
  }
  .Rtable--collapse > .Rtable-cell--foot {
    margin-bottom: 1em;
  }
}
.no-flexbox .Rtable {
  display: block;
}
.no-flexbox .Rtable > .Rtable-cell {
  width: 100%;
}
.no-flexbox .Rtable > .Rtable-cell--foot {
  margin-bottom: 1em;
}
