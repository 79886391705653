.stateDrivenButtonWrapper {

}
.stateDrivenButton {
  cursor: pointer;
  border:none;
  display:flex;
  margin: 8px;
  padding: 8px 16px;
  min-height: 36px;
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
}


.stateDrivenButtonOutline {
  color: #2980b9;
  border:1px solid #2980b9;
  background-color: #fff;
  outline:none;
}
.stateDrivenButtonOutline:hover {
  color: #fff;
  border:1px solid #2980b9;
  background-color: #2980b9;
}


.stateDrivenButtonSolid {
  color: #fff;
  border:1px solid #2980b9;
  background-color: #2980b9;

  /*box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);*/
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}

.stateDrivenButtonAttrHidden {
  display:none;
}
.stateDrivenButtonLeftIcon {
  padding-right:4px
}
.stateDrivenButtonRightIcon {
  padding-left:4px
}

/*********************************/

.shcbtn {
  cursor: pointer;
  border: 1px solid transparent;
  display:flex;
  padding: 8px 10px;
  /*min-height: 36px;*/
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  outline:none;
  background: inherit;
  margin:2px;
}

.shcbtn-fullwidth {
  width:100%;
}
.shcbtn-fullwidth div {
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}

.shcbtn-tiny {
  height:18px;
  font-size:10px;
  padding: 0 6px;
}

.shcbtn-small {
  height:25px;
  font-size:11px;
  padding: 0 10px;
  align-items: center;
}

.shcbtn-medium {
  min-height: 36px;
  font-size:13px;
  padding: 8px 10px; 
}
.shcbtn-large {
  min-height: 36px;
  font-size:16px;
  padding: 8px 10px; 
}
.shclogo-large {
  font-size:24px;
  padding: 0px 10px; 
}


.shcbtn-rounded {
  border-radius: 4px;
}

.shcbtn-primary {
  background-color: #2980b9;
  color: #fff;
}
.shcbtn-primary:hover {
  background-color: #20638f;
  color: #fff;
}
.shcbtn-secondary {
  background-color: #34495e;
  color: #fff;
}
.shcbtn-secondary:hover {
  background-color: #2b3c4e;
  color: #fff;
}
.shcbtn-success {
  background-color: #16a085;
  color: #fff;
}
.shcbtn-success:hover {
  background-color: #138a72;
  color: #fff;
}
.shcbtn-danger {
  background-color: #c0392b;
  color: #fff;
}
.shcbtn-danger:hover {
  background-color: #ab3326;
  color: #fff;
}
.shcbtn-warning {
  background-color: #e67e22;
  color: #fff;
}
.shcbtn-warning:hover {
  background-color: #d67118;
  color: #fff;
}
.shcbtn-info {
  background-color: #8e44ad;
  color: #fff;
}
.shcbtn-info:hover {
  background-color: #7f3d9b;
  color: #fff;
}


.shcbtn-negative {
  background-color: #efefef;
  color: #444;
}
.shcbtn-negative:hover {
  background-color: #bfbfbf;
  color: #444;
}




.shcbtn-outline-primary {
  border: 1px solid  #2980b9;
  color: #20638f;
}
.shcbtn-outline-primary:hover {
  border: 1px solid  #2980b9;
  color: #fff;
  background-color:  #2980b9;
}
.shcbtn-outline-secondary {
  border: 1px solid  #34495e;
  color: #22303d;
}
.shcbtn-outline-secondary:hover {
  border: 1px solid  #34495e;
  color: #fff;
  background-color:  #34495e;
}
.shcbtn-outline-success {
  border: 1px solid  #16a085;
  color: #107360;
}
.shcbtn-outline-success:hover {
  border: 1px solid  #16a085;
  color: #fff;
  background-color:  #16a085;
}
.shcbtn-outline-danger {
  border: 1px solid  #c0392b;
  color: #962d22;
}
.shcbtn-outline-danger:hover {
  border: 1px solid  #c0392b;
  color: #fff;
  background-color:  #c0392b;
}
.shcbtn-outline-warning {
  border: 1px solid  #e67e22;
  color: #bf6516;
}
.shcbtn-outline-warning:hover {
  border: 1px solid  #e67e22;
  color: #fff;
  background-color:  #e67e22;
}
.shcbtn-outline-info {
  border: 1px solid  #8e44ad;
  color: #703688;
}
.shcbtn-outline-info:hover {
  border: 1px solid  #8e44ad;
  color: #fff;
  background-color:  #8e44ad;
}








.shcbtn-default-primary {
  color: #20638f;
}
.shcbtn-default-primary:hover {
  border: 1px solid  #20638f;
  color: #fff;
  background-color:  #20638f;
}
.shcbtn-default-secondary {
  color: #22303d;
}
.shcbtn-default-secondary:hover {
  border: 1px solid  #2b3c4e;
  color: #fff;
  background-color:  #2b3c4e;
}
.shcbtn-default-success {
  color: #107360;
}
.shcbtn-default-success:hover {
  border: 1px solid  #138a72;
  color: #fff;
  background-color:  #138a72;
}
.shcbtn-default-danger {
  color: #962d22;
}
.shcbtn-default-danger:hover {
  border: 1px solid  #ab3326;
  color: #fff;
  background-color:  #ab3326;
}
.shcbtn-default-warning {
  color: #bf6516;
}
.shcbtn-default-warning:hover {
  border: 1px solid  #d67118;
  color: #fff;
  background-color:  #d67118;
}
.shcbtn-default-info {
  color: #703688;
}
.shcbtn-default-info:hover {
  border: 1px solid  #7f3d9b;
  color: #fff;
  background-color:  #7f3d9b;
}
.shcbtn-default-white {
  color: #fff;
}





.shcbtn-default-soft-primary {
  color: #20638f;
}
.shcbtn-default-soft-primary:hover {
  /*border: 1px solid  #20638f;*/
  color: #fff;
  background-color:  #90b1c7;
}
.shcbtn-default-soft-secondary {
  color: #22303d;
}
.shcbtn-default-soft-secondary:hover {
  /*border: 1px solid  #2b3c4e;*/
  color: #fff;
  background-color:  #959ea7;
}
.shcbtn-default-soft-success {
  color: #107360;
}
.shcbtn-default-soft-success:hover {
  /*border: 1px solid  #138a72;*/
  color: #fff;
  background-color:  #89c5b9;
}
.shcbtn-default-soft-danger {
  color: #962d22;
}
.shcbtn-default-soft-danger:hover {
  /*border: 1px solid  #ab3326;*/
  color: #fff;
  background-color:  #d59993;
}
.shcbtn-default-soft-warning {
  color: #bf6516;
}
.shcbtn-default-soft-warning:hover {
  /*border: 1px solid  #d67118;*/
  color: #fff;
  background-color:  #ebb88c;
}
.shcbtn-default-soft-info {
  color: #703688;
}
.shcbtn-default-soft-info:hover {
  /*border: 1px solid  #7f3d9b;*/
  color: #fff;
  background-color:  #b89bc4;
}











.outlineBtn {
  cursor: pointer;
  border:none;
  display:flex;
  margin: 8px;
  padding: 8px 16px;
  min-height: 36px;
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
}


.outlineBtnOutline {
  color: #2980b9;
  border:1px solid #2980b9;
  background-color: inherit;
  outline:none;
}
.outlineBtnOutline:hover {
  color: #fff;
  border:1px solid #2980b9;
  background-color: #2980b9;
}


.outlineBtnSolid {
  color: #fff;
  border:1px solid #2980b9;
  background-color: #2980b9;

  /*box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);*/
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}

.outlineBtnAttrHidden {
  display:none;
}
.outlineBtnLeftIcon {
  padding-right:4px
}
.outlineBtnRightIcon {
  padding-left:4px
}






/*********** BUTTON GROUP *************/
.buttonGroup {
  display: flex;
  cursor: pointer;
}
.buttonGroupItem {
  padding: 5px;
  border-top: 1px solid #16a085;
  border-bottom: 1px solid #16a085;
  border-left: 1px solid #16a085;
}

.buttonGroupItemActive {
  background-color: #16a085;
  color: #fff;
}
.buttonGroupItemActive::after {
  border-right:1px solid #fff;;
}

.buttonGroupItem:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid #16a085;
}
.buttonGroupItem:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid #16a085;
}

.buttonGroupItemBorderLeft {
  border-left: 1px solid #fff
}