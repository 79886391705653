

/**** PLAIN TEXT TABLES ****/



.__ShcPlainTextTable_key__ {
  /*min-width: 100px;*/
  color: #444;
}
.__ShcPlainTextTable_value__ {
  color: #444
}


/**** ENHANCED TABLES ****/
.EnhancedTablesToolbarTitle {
  flex: 0 0 auto;
}

.EnhancedTablesToolbarSpacer {
  flex: 1 1 100%;
}
.EnhancedTablesToolbarActions {
  color: red;
}

.EnhancedTablesToolbarHighlight {
  color: rgb(225, 0, 80);
  background-color: rgb(251, 224, 234);
}
.EnhancedTablesTableToolbarWrapper {
  overflow-x: auto;
}
.EnhancedTablesTable {
  min-width: 1020px;
}

/**** REACT TABLES ****/

.ReactTable {
  font-size:12px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.ReactTable .rt-thead {
  font-size: 14px;
}
.ReactTable * {
  outline: none;
}
.ReactTable .rt-tr:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.ReactTable .rt-thead.-header {
  border-bottom: 1px solid rgba(0,0,0,0.02);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.02);
}
.ReactTable .-pagination {
  box-shadow: none;
}


/**** TABLE LIST FACING ****/
.TableListFacing_container {
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
}

.TableListFacing_titleText {
  text-align: right;
  padding-right:20px;
  font-weight: bold;
}
.TableListFacing_contentText {
  text-align: left;
}

/* DivTable.com */
.TableListFacing_divTable{
	display: table;
	/*width: 100%;*/
}
.TableListFacing_divTableRow {
	display: table-row;
}
.TableListFacing_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.TableListFacing_divTableCell, .TableListFacing_divTableHead {
	display: table-cell;
	padding: 3px 10px;
}
.TableListFacing_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.TableListFacing_divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.TableListFacing_divTableBody {
	display: table-row-group;
}

