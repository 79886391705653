

.demoSecurityCodeContainer {
    border: 5px solid #444;
    padding: 20px;
    margin: 10px;
}
.demoSecurityTitle {

}
.demoSecurityCode {
    font-size: 26px;
}
