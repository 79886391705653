


.__json-pretty__ {
  line-height: 1.3;
  color: #000;
  overflow: auto;
}

.__json-key__ {
  color: #34495e;
}
.__json-value__ {
  color: #c0392b;
}
.__json-string__ {
  color: #16a085;
}
.__json-boolean__ {
  color: #067ab8;
  font-weight: bold;
}




.userLoginPage_loginContainer {
  max-width: 430px;
  border: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
}
.userLoginPage_headerContainer {
  padding-top:10px;
  font-size: 30px;
  text-align: center;
  padding:5px;
}
.userLoginPage_emailLoginContainer {
  padding:5px;
}
.userLoginPage_divider {
  margin-top:10px;
  border-top:1px solid #eee;
  padding-top:10px;
  padding-bottom:10px;
  text-align: center;
  background-color: #f9f9f9;
}
.userLoginPage_socialloginContainer {
  font-size:14px;
  background-color: #f9f9f9;
  padding-top:5px;
  padding-bottom:20px;
  border-bottom:1px solid #eee;
  margin-bottom:10px;
}
.userLoginPage_socialButtonStyles {
  font-size:14px !important;
}
.userLoginPage_footer {
  font-size:12px;
  padding:5px;
}

