


.Dashboard_primaryHeader {
    font-size: 30px;
    color: #444;
    padding-bottom: 10px;
}
.Dashboard_primaryHeader_marginTop {
    padding-top: 30px;
}
.Dashboard_horizontalDivide {
    margin-top: 40px;
    border-color: #fff;
}
