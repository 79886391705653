.dividerHorizontalRule {
  border-top: 1px solid #ddd;
}
.businessVerificationPreferenceControlsContainer {
  border-left: 2px solid #ddd;
  padding-left:20px;
}

.errorAgentInput {
  border: 1px solid #d14233;
  background-color: #c0392b;
  border-radius: 5px;
  color: #fff;
  margin: 0 3px;
  padding: 1px 3px;
}