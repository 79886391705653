.linkGroup_navigationLinks {
  color: #3F3F3F /*#2a3948*/;
  font-size: 14px;
  flex-grow: 1;
}
.linkGroup_navigationLinkHighlight {
  color: #2980b9;
  background-color: #fff;
}
.linkGroup_navigationLinksChild {
  font-size: 12px;
}



.linkGroup_narrowSideBarContent {
  width:64px;
  text-align: center;
  margin-left:1px;
  margin-right:7px;
}
.linkGroup_narrowSideBarContent_BackButton {
  width:25px;
  text-align: center;
  margin-left:1px;
  margin-right:7px;
}

.linkGroup_navLinkSecondaryText {
  font-size:12px;
  text-align: center;
}
.linkGroup_navLinkSecondaryTextChildren {
  font-size:10px;
  text-align: center;
}
.linkGroup_listBackItem {
  margin-left:25px;
  /*border-bottom:1px solid #E7E9E9;*/
  display: flex;
  flex-grow: 1;
}

.linkGroup_extendedSideBarTitle {
  font-size:14px;
}
.linkGroup_extendedSideBarBackTitle {
  font-size:14px;
  font-weight:bold;
  color:#3F3F3F;
}
.linkGroup_extendedSideBarBackIcon {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  color:#3F3F3F;
}

.linkGroup_extendedSideBarBackIconLeft {
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
  font-size: 20px;
  color:#3F3F3F;
}


.linkGroup_collapsedSideBarTitle {
  font-size:12px;
}
.linkGroup_extendedSideBarContentCollapse {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}


.linkGroup_divLineDropDownIcon {
  position:relative; /* Position must be relative to absolutely position :after */
  width:calc(100% - 3px);
  border-top:1px solid #a7b1c2;
  margin-left:3px;
  margin-top: 4px;
}

.linkGroup_divLineDropDownIcon:after{
  content:''; /* Required to display content */
  position: absolute; /* Sets the position absolute to the top div */
  top: 0; 
  left: 50%;
  margin-left: -7px; /* Set margin equal to border px */
  width: 0;
  z-index:1;
  height: 0;
  border-top: solid 5px #a7b1c2; /* Creates the notch */
  border-left: solid 5px transparent; /* Creates triangle effect */
  border-right: solid 5px transparent; /* Creates triangle effect */
}





.linkGroup_listItemIcon {
  flex-shrink: 0;
  margin-left: 21px;
  margin-right: 34px;
}

.linkGroup_dividerLineCollapse {
  border-bottom:1px solid rgba(167,177,194, 0.2);
}


.hide {
  display:none;
}


.linkGroup_listItem {
  /*width: 250px; /*larger than the expanded view*//* REMOVED TO HELP SMALL SCREENS */
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
}
.linkGroup_listItemMax {
  width: 250px;
}
.linkGroup_listItem:hover {
  /*background-color:#253340;*/
  background-color: rgba(252, 253, 255, 0.2);
  color:#2980b9;
}

.linkGroup_listItemParent {
  padding-top:10px;
  padding-bottom:10px;
}


.linkGroup_listItemChild {
  /*padding-left:15px;
  background-color:#2a3948;
  padding-top:8px;
  padding-bottom:8px;*/
  margin-top:3px;
  margin-bottom:3px;
  padding-top:8px;
  padding-bottom:8px;
  /*background-color:#2a3948;*/
  border-left:3px solid #2980b9;
  text-align: left;
}


.linkGroup_listItemIconChild {
  /*flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;*/
}

.linkGroup_listItemExpandArrow {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  padding-right:30px;
}