
.ShcModulesIdentityPrimaryContainer{
  padding: 0;
  border-left: 5px solid #2980b9;
}


.ShcModulesIdentityContainer {
  padding:0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
}
.ShcModulesIdentityContainerHeader {
    background-color: #fafafa;
    color:#000;
    padding:10px;
    border-bottom: 1px solid #eaeded;
  
}

.ShcModulesIdentityStatusContainerHeader {
  color: #fff;
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.ShcModulesIdentityStatusContainerBody {
  padding: 10px 15px 5px 15px;
  background-color: #fff;
}

.ShcModulesIdentityRemoveItem {
  position: absolute;
  right: 10px;
  color: #879596;
  cursor: pointer;
}




