/**** SCH FOOTER ****/

/**** FOOTER PRIMARY LAYOUT ****/
.shcFooterPrimaryLayout {
  background-color: #343A40;
  overflow: hidden;
}
.shcFooterPrimaryLayout_content {
  
}

@media (max-width: 959px) {
  .shcFooterPrimaryLayout {
    margin-left:0;
  }
}
@media (min-width: 960px) {
  .shcFooterPrimaryLayout {
    /*margin-left:72px;*/
  }
}

/**** FOOTER PRIMARY CONTENT ****/
.shcFooterPrimaryContent {

}
.shcFooterPrimaryContent_container {
  color: #fff;
  padding: 10px;
}

/**** FOOTER PRIMARY LINKS ****/
.shcFooterPrimaryLinks {
  background-color: #2980b9;
}
.shcFooterPrimaryLinks_container {
  color: #fff;
  padding: 4px 10px 4px 10px;
}

/**** FOOTER PRIMARY LINKS ****/
.shcFooterBaseSocial {
  background-color: #495057;
}
.shcFooterBaseSocial_container {
  color: #fff;
  padding: 4px 10px 15px 10px;
}

/**** FOOTER PURPOSE / COPYRIGHT CONTENT ****/
.shcFooterPurposeCopyrightContent {

}
.shcFooterPurposeCopyrightContent_container {
  color: #fff;
}