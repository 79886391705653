
.ShcModulesIdentityPrimaryContainer{
  padding: 0;
  border-left: 5px solid #2980b9;
}


.ShcModulesIdentityContainer {
  padding:0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
}
.ShcModulesIdentityContainerHeader {
    background-color: #fafafa;
    color:#000;
    padding:10px;
    border-bottom: 1px solid #eaeded;
  
}

.ShcModulesIdentityStatusContainerHeader {
  color: #fff;
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.ShcModulesIdentityStatusContainerBody {
  padding: 10px 15px 5px 15px;
  background-color: #fff;
}

.ShcModulesIdentityRemoveItem {
  position: absolute;
  right: 10px;
  color: #879596;
  cursor: pointer;
}




/**************************************************/
.ShcModulesApiMgt_removeItem {
  color: #879596;
  cursor: pointer;
}
.ShcModulesApiMgt_keyText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
}
.ShcModulesApiMgt_keyText_small {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.75rem;
  line-height: 1rem;
  display: inline-block;
}

.ShcModulesApiMgt_container {
  padding: 0;
}
.ShcModulesApiMgt_maskedKey {

}
.ShcModulesApiMgt_maskedKeyReveal {
  padding-left: 20px;
}


.ShcModulesApiMgt_keyCreatedText {
  font-size: 11px;
  padding: 0 10px;
}
.ShcModulesApiMgt_keyAgeText {
  font-size: 11px;
  padding: 0 10px;
}
