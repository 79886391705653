/**** SHC HEADER ****/

.shcHeader_parentSidebarMain {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;
}
.shcHeader_parentSidebarSmall {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

.shcHeader_parentSidebarSectionDivider {
  border-color: #444;
}

.shcHeader_parentSidebarFooterPadding {
  padding-bottom: 60px;
}


@media (max-width: 659px) {
  .shcHeader_parentSidebarPermanent {
    display:none;
  }
  .shcHeader_parentSidebarTemporary {
    display:flex;
  }

  .shcHeader_parentSidebarPermanentOpen {
    width:240px;
  }
  .shcHeader_parentSidebarPermanentClose {
    width:72px;
  }

  .shcHeader_parentSidebarTemporaryOpen {
    width:240px;
  }
  .shcHeader_parentSidebarTemporaryClose {
    width:0;
  }

}
@media (min-width: 660px) {
  .shcHeader_parentSidebarPermanent {
    display:flex;
  }
  .shcHeader_parentSidebarTemporary {
    display:none;
  }

  .shcHeader_parentSidebarPermanentOpen {
    width:240px;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
  .shcHeader_parentSidebarPermanentClose {
    width:72px;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .shcHeader_parentSidebarTemporaryOpen {
    width:240px;
  }
  .shcHeader_parentSidebarTemporaryClose {
    width:72px;
  }
}


.shcHeader_appbarContainer {
  display: flex;
  align-items: center;
  padding: 0;
  height:45px;
}

/* NEW to support removal of style*/
.shcHeader_appbarHeightStyle {
  margin-top: 45px;
}







.shcHeader {
  display: flex;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.shcHeader_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #2980b9;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*/

  width: 100%;
  /*width: 100vw;*/
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  min-height: 45px;
  height:45px;  /* fix for safari not showing */

}



.shcHeader_toolbarDrawer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  height: 45px;
  color: #a7b1c2;

}

.shcHeader_iconContainer {
  /*margin-left:15px;*/
  width:72px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.shcHeader_NavIcon {
  font-size: 20px;
}
.shcHeader_Title {
  font-size: 20px;
}

/***** SIDE BAR COMPONENT *****/
.shcHeader_drawerPaper {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;

  padding-top: 40px;
}
.shcHeader_drawerPaperClose {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

.shcHeader_navigationSideBar {
  color: #a7b1c2;
  overflow: auto;
  overflow-x: hidden;
  height:100%;
}



.shcHeader_drawerPaperTemporary {
  position: absolute;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;
}

.shcHeader_drawerPaperTemporaryClose {
  overflow-x: hidden;
  /*transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;*/
  width: 0;
}
.shcHeader_contentSection {
  min-height: 90vh;
}
.shcHeader_contentPadding {
  /*padding: 24px; Moved to Child to manage*/
  width:100%;
}
/*Support fixed screen view*/
.shcHeader_fixedView {
  width:100%;
  height: 100vh;
}
.hide {
  display: none !important;
}
.shcHeader_sidebarTrackHorizontal {
  display:none;
}
.shcHeader_navBarControls {
  display:flex;
  flex-grow:1;
  justify-content:flex-end;
}


@media (max-width: 959px) {
  .shcHeader_contentMarginPermanent {
    margin-left:0;
  }
  .shcHeader_contentMarginOpen {
    margin-left: 0;
  }
}
@media (min-width: 960px) {
  .shcHeader_contentMarginPermanent {
    margin-left:72px;
  }
  .shcHeader_contentMarginOpen {
    margin-left: 240px;
  }
}

/************* SITE HEADER SUB PANEL **************/


.shcHeaderSubPanel {
  display: flex;
  flex-grow: 1;
  height: 45px;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.shcHeaderSubPanel_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #495057;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*/

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  margin-top: 45px;
  min-height: 45px;
}
.shcHeaderSubPanel_toolbar {
  display: flex;
  align-items: center;
  padding: 0;
  /*height:45px;*/
}
.shcHeaderSubPanel_contentContainer {
  padding: 0;
  width: 100%;
}

@media (max-width: 959px) {
  .shcHeaderSubPanel_contentMarginPermanent {
    margin-left:0;
  }
}
@media (min-width: 960px) {
  .shcHeaderSubPanel_contentMarginPermanent {
    margin-left:72px;
  }
}

/*********** SIDE BAR SUB NAV ************/
/*
.SidebarSubNav_panel {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
  flex-grow: 1;
}
*/

.hide {
  display:none;
}

.SidebarSubNav_foldContent {
  align-items: stretch;
  flex-grow:1;
  background-color: inherit;
  /*border: 2px solid rgb(241, 243, 246);*/
  padding: 20px;
}

.scrollBars_renderThumbVertical {
  position: absolute; 
  top: 0px; 
  left: 0px; 
  right: 0px; 
  bottom: 0px; 
  overflow: scroll; 
  margin-right: -17px; 
  margin-bottom: -17px;

}

.scrollBars_style {
  background-color: '#a7b1c2';
  width: '5px';
  opacity: '0.5';
}


