
body {
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  background-color: #f2f3f3;
}

h1 {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
}


/* TEMPLATE */

@media only screen and (max-width: 230px) {

}
@media only screen and (min-width: 230px) and (max-width: 330px) {

}
@media only screen and (min-width: 330px) and (max-width: 430px) {

}
@media only screen and (min-width: 430px) and (max-width: 530px) {

}
@media only screen and (min-width: 530px) and (max-width: 700px) {

}
@media only screen and (min-width: 700px) and (max-width: 830px) {

}
@media only screen and (min-width: 830px) and (max-width: 1060px) {

}
@media only screen and (min-width: 1060px) and (max-width: 1250px) {

}
@media only screen and (min-width: 1250px) {

}



    
.publicLayout_insetPanelTopMargin {
    margin-top: 20px;
}
.publicLayout_insetPanelNoPadding {
}
@media only screen and (max-width: 230px) {
    .publicLayout_insetPanel {
        margin-left: 0px;
        margin-right: 0px;
        padding: 5px;
    }
    .publicLayout_insetPanelNoPadding {
      margin-left: 0px;
      margin-right: 0px;
    }    
}
@media only screen and (min-width: 230px) and (max-width: 330px) {
    .publicLayout_insetPanel {
        margin-left: 10px;
        margin-right: 10px;
        padding: 20px;
    }
    .publicLayout_insetPanelNoPadding {
      margin-left: 10px;
      margin-right: 10px;
    }  
}
@media only screen and (min-width: 330px) {
    .publicLayout_insetPanel {
        margin-left: 20px;
        margin-right: 20px;
        padding: 40px;
    }
    .publicLayout_insetPanelNoPadding {
      margin-left: 20px;
      margin-right: 20px;
    }  
}

.publicLayout_insetPanel_smallHeadline {
    display: flex;
    justify-content: center;
}
.publicLayout_insetPanel_smallHeadlineText {
    color: #777;
    font-size: 13px;
}
.publicLayout_insetPanel_header {
    display: flex;
}
.publicLayout_insetPanel_headerLeft {

}
.publicLayout_insetPanel_headerRight { 
    flex-grow: 1;
    justify-content: flex-end;
    align-self: center;
}
.publicLayout_insetPanel_primary {
    display: flex;
    justify-content: center;
}

.publicLayout_insetPanel_primaryText { 
    font-weight: bold;
    color: #444;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 900px;
}
.publicLayout_insetPanel_secondaryText { 
  margin-top:20px;
  color: #444;
}

@media only screen and (max-width: 230px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 20px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 16px;
    }
    .headlineOurVision {
      font-size: 12px;
    }
    .headlineOurVisionText {
      font-size: 18px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 200px;
    }
}
@media only screen and (min-width: 230px) and (max-width: 330px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 20px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 16px;
    }
    .headlineOurVision {
      font-size: 12px;
    }
    .headlineOurVisionText {
      font-size: 18px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 200px;
    }
}
@media only screen and (min-width: 330px) and (max-width: 430px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 30px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 16px;
    }
    .headlineOurVision {
      font-size: 12px;
    }
    .headlineOurVisionText {
      font-size: 18px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 200px;
    }
}
@media only screen and (min-width: 430px) and (max-width: 530px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 30px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 18px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 24px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 200px;
    }
}
@media only screen and (min-width: 530px) and (max-width: 700px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 30px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 18px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 24px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 100px;
    }
}
@media only screen and (min-width: 700px) and (max-width: 830px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 40px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 20px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 28px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 100px;
    }
}
@media only screen and (min-width: 830px) and (max-width: 1060px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 40px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 20px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 28px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 100px;
    }
}
@media only screen and (min-width: 1060px) and (max-width: 1250px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 50px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 20px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 28px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 100px;
    }
}
@media only screen and (min-width: 1250px) {
    .publicLayout_insetPanel_primaryText { 
        font-size: 60px;
    }
    .publicLayout_insetPanel_secondaryText { 
      font-size: 20px;
    }
    .headlineOurVision {
      font-size: 18px;
    }
    .headlineOurVisionText {
      font-size: 28px;
    }
    .guardPointOnAppStoreContainerHeight {
      height: 100px;
    }
}
.publicLayout_insetPanel_centerAlign {
    display: flex;
    justify-content: center;
}
.publicLayout_insetPanel_secondaryHeadline { 
    color: #444;
    font-size: 16px;

}

.guardPointLogo_inlineMax300 {
    width: 100%;
    height: auto;
    max-width: 300px;
}
.guardPointLogo_inlineMax200 {
    width: 100%;
    height: auto;
    max-width: 200px;
}

.guardPointLogoUniversalBusinessAuthenticator_inlineMax500 {
    width: 100%;
    height: auto;
    max-width: 500px;
}

.freeOnAppStoresText {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.mobileAppLinks_inlineMax200 {
    width: 100%;
    height: auto;
    max-width: 200px;
  }



































































/************ REACT PASSWORD STRENGTH *******/
.ReactPasswordStrength {
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  color: #090809;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  position: relative;
}
.ReactPasswordStrength:focus {
  outline: none;
}
.displayBlock {
  display: block;
}
.ReactPasswordStrength-input {
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  padding: 14px 0 12px 14px;
  width: calc(85% - 28px);
}
.ReactPasswordStrength-input:focus {
  outline: none;
}
.ReactPasswordStrength-strength-bar {
  box-sizing: border-box;
  height: 2px;
  position: relative;
  top: 1px;
  right: 1px;
  transition: width 300ms ease-out;
  width: 0;
}
.ReactPasswordStrength-strength-desc {
  color: transparent;
  font-style: italic;
  padding: 14px 12px;
  position: absolute;
  top: 1px;
  right: 0;
  text-align: right;
  transition: color 250ms ease-in-out;
  width: 15%;
}
.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
  color: #D1462F;
}
.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
  color: #D1462F;
}
.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
  color: #D1462F;
}
.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
  color: #57B8FF;
}
.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
  color: #2FBF71;
}



/************ REACT DROPDOWN *******/
.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

















.wrap {
  text-align: center;
  margin: 0px;
  position: relative;
  height: 25px;
}
.links {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.wrap:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #bbb;
  background: #bbb;
  width: 100%;
  /*transform: translateY(-50%);*/
}

.minWidthGrow600 {
  min-width:600px;
  overflow: hidden;
}


.textDecoration {
  text-decoration: none;
}




.listNoMarginPadding {
  margin: 0;
  padding: 0;
}


.noLinkStyle {
  text-decoration: none;
  color: inherit;
}


.whiteSpacePreWrap {
  white-space: pre-wrap;
}

.positionRelative {
  position: relative;
}
.positionAbsolute {
  position: absolute;
}

.cursorPointer {
  cursor: pointer;
}

/* WIDTHS */
.width100p {
  width: 100%;
}
.width10 {
  width: 10px;
}
.width20 {
  width: 20px;
}
.width30 {
  width: 30px;
}
.width40 {
  width: 40px;
}
.width50 {
  width: 50px;
}
.width60 {
  width: 60px;
}

.width75 {
  width: 75px;
}
.width80 {
  width: 80px;
}
.width90 {
  width: 90px;
}
.width120 {
  width: 120px;
}
.width130 {
  width: 130px;
}
.width140 {
  width: 140px;
}
.width250 {
  width: 250px;
}
.width400 {
  width: 400px;
}

.maxWidth30 {
  max-width: 30px;
}
.maxWidth80 {
  max-width: 80px;
}
.maxWidth100 {
  max-width: 100px;
}
.maxWidth200 {
  max-width: 200px;
}
.maxWidth250 {
  max-width: 250px;
}
.maxWidth300 {
  max-width: 300px;
}
.maxWidth350 {
  max-width: 350px;
}
.maxWidth400 {
  max-width: 400px;
}
.maxWidth450 {
  max-width: 450px;
}
.maxWidth600 {
  max-width: 600px;
}
.maxWidth800 {
  max-width: 800px;
}
.maxWidth900 {
  max-width: 900px;
}
.maxWidth1000 {
  max-width: 1000px;
}

.maxWidth10p {
  max-width: 10%;
}
.maxWidth25p {
  max-width: 25%;
}
.maxWidth50p {
  max-width: 50%;
}
.maxWidth75p {
  max-width: 75%;
}
.maxWidth100p {
  max-width: 100%;
}

.minWidth5 {
  min-width: 5px;
}
.minWidth10 {
  min-width: 10px;
}
.minWidth15 {
  min-width: 15px;
}
.minWidth18 {
  min-width: 18px;
}
.minWidth20 {
  min-width: 20px;
}
.minWidth30 {
  min-width: 30px;
}
.minWidth40 {
  min-width: 40px;
}
.minWidth70 {
  min-width: 70px;
}
.minWidth110 {
  min-width: 110px;
}
.minWidth150 {
  min-width: 150px;
}
.minWidth200 {
  min-width: 200px;
}
.minWidth250 {
  min-width: 250px;
}
.minWidth350 {
  min-width: 350px;
}
.minWidth400 {
  min-width: 400px;
}
.minWidth450 {
  min-width: 450px;
}

.maxHeight100 {
  max-height: 100px;
}
.maxHeight120 {
  max-height: 120px;
}
.maxHeight200 {
  max-height: 200px;
}
.maxHeight300 {
  max-height: 300px;
}
.maxHeight400 {
  max-height: 400px;
}

.maxHeight580 {
  /*Apps image to support safari*/
  max-height: 580px;
}

.height30 {
  height: 30px;
}
.height100 {
  height: 100px;
}
.height120 {
  height: 120px;
}
.height200 {
  height: 200px;
}
.height250 {
  height: 250px;
}
.height300 {
  height: 300px;
}
.height400 {
  height: 400px;
}


.minWidth100p {
  min-width: 100%;
}

.toastSuccess {
  background-color: rgb(227, 252, 239);
  color: rgb(0, 102, 68);
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  border-radius: 4px;
  font-size:14px;
}
.toastSuccessProgress {
  background-color: rgb(55, 158, 105);
}
.Toastify__close-button--success {
  color: rgb(0, 102, 68);
}

.toastWarning {
  background-color: rgb(255, 250, 230);
  color: rgb(255, 139, 0);
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  border-radius: 4px;
  font-size:14px;
}
.toastWarningProgress {
  background-color: rgb(255, 139, 0);
}
.Toastify__close-button--warning {
  color: rgb(255, 139, 0);
}

.toastError {
  background-color: rgb(255, 235, 230);
  color: rgb(191, 38, 0);
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  border-radius: 4px;
  font-size:14px;
}
.toastErrorProgress {
  background-color: rgb(191, 38, 0);
}
.Toastify__close-button--error {
  color: rgb(191, 38, 0);
}

.toastInfo {
  background-color: #3498db;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  border-radius: 4px;
  font-size:14px;
}
.toastInfoProgress {
  background-color: #2980b9;
}
.Toastify__close-button--info {
  color: #fff;
}

.toastDefault {
  background-color: #fff;
  color: #444;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  border-radius: 4px;
  font-size:14px;
}
.toastDefaultProgress {
  background-color: #2980b9;
}
.Toastify__close-button--default {
  color: #444;
}

.displayBlock {
  display: block;
}

.hidden {
  display: none;
}
.textAlignCenter {
  text-align: center;
}
.textAlignRight {
  text-align: right;
}
.textAlignLeft {
  text-align: left;
}

/* MATERIAL UI FIXES */
.gridCeilingMargin12 {
  margin-top:12px;
}



/*main heading on each page - needs to be updated*/
.LinkGroupLayout_ContainerTitle {
  font-size: 30px;
}
.sidebarSubNav_headlineSmall {
  font-size: 12px;
  text-align:center;
}
.sidebarSubNav_headlineLarge {
  font-size: 16px;
  text-align:center;
}
.sidebarSubNav_headlineTiny {
  font-size: 11px;
  text-align:center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom:4px;
}
.sidebarSubNav_NavLinkSectionHeader {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 4px;
  padding-top: 10px;
  font-weight: bold;
  color: #3F3F3F;
}

/****** CONTENT WRAPPERS ******/
.contentPad10 {
  padding:10px;
}
.contentPad20 {
  padding:20px;
}



.pageWrapper {
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  width:100%;
  padding: 0;
  flex-direction: column;
}

.noLinkStyles {
  text-decoration: none;
}

.height100p {
  height: 100%;
}

.commonStylesTest {
  background-color: black;
  color: #fff;
}

/******* BASICS ******/
.fullHeight {
  height:100%;
}
.height450 {
  height: 450px;
}
.height650 {
  height: 650px;
}

.minHeight450 {
  min-height: 450px;
}

.minHeight90vh {
  min-height:90vh;
}

.fullwidth {
  width:100%;
}

.width50p {
  width:50%;
}
.width200pix {
  width:200px;
}

.width10 {
  width:10px;
}
.width20 {
  width:20px;
}
.width30 {
  width:30px;
}
.width40 {
  width:40px;
}
.width50 {
  width:50px;
}
.width60 {
  width:60px;
}
.width70 {
  width:70px;
}
.width80 {
  width:80px;
}
.width90 {
  width:90px;
}
.width100 {
  width:100px;
}

.minHeight25 {
  min-height: 25px;
}

.minHeight50 {
  min-height: 50px;
}
.height50 {
  height: 50px;
}

.maxWidth100p {
  max-width: 100%;
}





.contentTitleBar {
  padding-bottom:20px;
}
.gridItem {
  padding-top:0 !important;
  padding-bottom:0 !important;
}




/* FORMS & STYLES */
.formSectionTitle {
  font-size: 18px;
  padding-top:40px;
  padding-bottom:15px;
  color: #444;
}
.stripeFormSectionOptions {
  font-size: 12px;
  color: #444;
}

.color_primary_dark {
  color: #444;
}
.color_primary_light {
  color: #fff;
}
.color_primary_medium {
  color: rgba(0, 0, 0, 0.54);
}

.color_primary_medium {
  color: rgba(0, 0, 0, 0.54);
}

.colour_gp_primary_text {
  color: #424242;
}


.colour_primary {
  color: #2980b9;
}
.colour_secondary {
  color: #34495e;
}
.colour_success {
  color: #16a085;
}
.colour_danger {
  color: #c0392b;
}
.colour_warning {
  color: #e67e22;
}
.colour_info {
  color: #8e44ad;
}
.colour_white {
  color: #fff;
}

.colour_lightGrey {
  color: #7f8c8d;
}

.bg_colour_primary {
  background-color: #2980b9;
}
.bg_colour_secondary {
  background-color: #34495e;
}
.bg_colour_success {
  background-color: #16a085;
}
.bg_colour_danger {
  background-color: #c0392b;
}
.bg_colour_warning {
  background-color: #e67e22;
}
.bg_colour_info {
  background-color: #8e44ad;
}
.bg_colour_white {
  background-color: #fff;
}

.bg_colour_panelWhite {
  background-color: #fefefe;
}

.bg_color_grey_dark {
  background-color: #343A40;
}
.bg_color_grey_light {
  background-color: #495057;
}

.bg_color_grey_f2 {
  background-color: #f2f3f3;
}
.bg_color_grey_c7 {
  background-color: #C7C7C7;
}
.bg_color_grey_e9 {
  background-color: #e9e9e9;
}




.text_color_grey_light {
  color: #545b64;
}
.text_color_grey_soft {
  color: #879596;
}

.text_color_grey_navlinks {
  color: #3F3F3F;
}



.heading_h1 {
  font-size: 32px;
  padding-top:40px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h2 {
  font-size: 24px;
  padding-top:30px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h3 {
  font-size: 18px;
  padding-top:30px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h4 {
  font-size: 16px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h5 {
  font-size: 14px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h6 {
  font-size: 12px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h7 {
  font-size: 11px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}

.text_s40 {
  font-size: 40px;
}
.text_s36 {
  font-size: 36px;
}
.text_s28 {
  font-size: 28px;
}
.text_s26 {
  font-size: 26px;
}
.text_s24 {
  font-size: 24px;
}
.text_s20 {
  font-size: 20px;
}
.text_s18 {
  font-size: 18px;
}
.text_s16 {
  font-size: 16px;
}
.text_s14 {
  font-size: 14px;
}
.text_s12 {
  font-size: 12px;
}
.text_s11 {
  font-size: 11px;
}

.text_h1 {
  font-size: 32px;
}
.text_h2 {
  font-size: 24px;
}
.text_h3 {
  font-size: 18px;
}
.text_h4 {
  font-size: 16px;
}
.text_h5 {
  font-size: 14px;
}
.text_h6 {
  font-size: 12px;
}
.text_h7 {
  font-size: 11px;
}
.text_h8 {
  font-size: 10px;
}
.text_h9 {
  font-size: 9px;
}

.text_fs13 {
  font-size: 13px;
}



.text_30 {
  font-size: 30px;
}
.text_22 {
  font-size: 22px;
}



.fontWeightBold {
  font-weight: bold;
}

.bgWhite {
  background-color:#fff;
}

.wordWrapBreak {
  word-break: break-all;
}
.wordWrapBreakWord {
  word-break: break-word;
}
/*
.overflowWrapAnywhere {
  overflow-wrap: anywhere;
}
*/

.pad4 {
  padding:4px;
}
.pad10 {
  padding:10px;
}
.pad15 {
  padding:15px;
}
.pad20 {
  padding:20px;
}
.pad25 {
  padding:25px;
}
.pad30 {
  padding:30px;
}
.pad40 {
  padding:40px;
}

.padB8 { 
  padding-bottom:8px;
}
.padB10 {
  padding-bottom:10px;
}
.padB15 {
  padding-bottom:15px;
}
.padB20 {
  padding-bottom:20px;
}
.padB25 {
  padding-bottom:25px;
}
.padB30 {
  padding-bottom:30px;
}
.padB40 {
  padding-bottom:40px;
}
.padB50 {
  padding-bottom:50px;
}
.padB60 {
  padding-bottom:60px;
}
.padB70 {
  padding-bottom:70px;
}
.padB80 {
  padding-bottom:80px;
}

.padT10 {
  padding-top:10px;
}
.padT15 {
  padding-top:15px;
}
.padT20 {
  padding-top:20px;
}
.padT25 {
  padding-top:25px;
}
.padT30 {
  padding-top:30px;
}
.padT40 {
  padding-top:40px;
}
.padT50 {
  padding-top:50px;
}
.padT60 {
  padding-top:60px;
}
.padT70 {
  padding-top:70px;
}
.padT80 {
  padding-top:80px;
}


.padR1 {
  padding-right:1px;
}
.padR2 {
  padding-right:2px;
}
.padR3 {
  padding-right:3px;
}
.padR4 {
  padding-right:4px;
}
.padR5 {
  padding-right:5px;
}

.padR7 {
  /*Required for icon adjustments on identityInput*/
  padding-right:7px;
}


.padR10 {
  padding-right:10px;
}
.padR15 {
  padding-right:15px;
}
.padR20 {
  padding-right:20px;
}
.padR25 {
  padding-right:25px;
}
.padR30 {
  padding-right:30px;
}




.padL1 {
  padding-left:1px;
}
.padL2 {
  padding-left:2px;
}
.padL3 {
  padding-left:3px;
}
.padL4 {
  padding-left:4px;
}
.padL5 {
  padding-left:5px;
}
.padL10 {
  padding-left:10px;
}
.padL15 {
  padding-left:15px;
}
.padL20 {
  padding-left:20px;
}
.padL25 {
  padding-left:25px;
}
.padL30 {
  padding-left:30px;
}

.padLR2 {
  padding-left:2px;
  padding-right:2px;
}
.padLR5 {
  padding-left:5px;
  padding-right:5px;
}
.padLR10 {
  padding-left:10px;
  padding-right:10px;
}
.padTB10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mar5 {
  margin:5px;
}
.mar10 {
  margin:10px;
}
.mar20 {
  margin:20px;
}
.mar30 {
  margin:30px;
}

.marB10 {
  margin-bottom:10px;
}
.marB15 {
  margin-bottom:15px;
}
.marB20 {
  margin-bottom:20px;
}
.marB25 {
  margin-bottom:25px;
}
.marB30 {
  margin-bottom:30px;
}

.marT10 {
  margin-top:10px;
}
.marT15 {
  margin-top:15px;
}
.marT20 {
  margin-top:20px;
}
.marT25 {
  margin-top:25px;
}
.marT30 {
  margin-top:30px;
}
.marT60 {
  margin-top:60px;
}

.marR10 {
  margin-right:10px;
}
.marR15 {
  margin-right:15px;
}
.marR20 {
  margin-right:20px;
}
.marR25 {
  margin-right:25px;
}
.marR30 {
  margin-right:30px;
}

.marL10 {
  margin-left:10px;
}
.marL15 {
  margin-left:15px;
}
.marL20 {
  margin-left:20px;
}
.marL25 {
  margin-left:25px;
}
.marL30 {
  margin-left:30px;
}

.marLRMinus10 {
  margin-left:-10px;
  margin-right:-10px;
}

.marLR2 {
  margin-left:2px;
  margin-right:2px;
}
.marLR5 {
  margin-left:5px;
  margin-right:5px;
}
.marLR10 {
  margin-left:10px;
  margin-right:10px;
}

.borderTLight {
  border-top: 1px solid #bbb;
}
.borderRLight {
  border-right: 1px solid #bbb;
}
.borderBLight {
  border-bottom: 1px solid #bbb;
}
.borderLLight {
  border-left: 1px solid #bbb;
}

.borderBlack1 {
  border: 1px solid #000;
}


.borderGreyLight {
  border: 1px solid #999;
}




.containerStyleLight {
  background-color: #fafafa;
  border: 1px solid #bbb;
}

.notificationDevices_notificationsOn {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #eee;
}
.notificationDevices_notificationsOff {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #eee;
}


/* COMMON LIST STYLES */
.listNone {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* COMMON STYLES */
.flex {
  display:flex;
}
.flexEvenChild {
  flex: 1 1 0px;
}
.flexColumn {
  flex-direction: column;
}
.flexRow {
  flex-direction: row;
}
.flexJustifySelfEnd {
  justify-self: flex-end;
}
.flexJustifySelfStart {
  justify-self: flex-start;
}
.flexJustifySelfCenter {
  justify-self: center;
}


.flexJustifyCenter {
  justify-content: center;
}
.flexJustifySpaceBetween {
  justify-content: space-between;
}
.flexJustifySpaceAround {
  justify-content: space-around;
}
.flexJustifyFlexStart{
  justify-content: flex-start;
}
.flexJustifyFlexEnd {
  justify-content: flex-end;
}

.flexAlignItemsCenter {
  align-items: center;
}
.flexAlignSelfCenter {
  align-self: center;
}
.flexAlignSelfStart {
  align-self: self-start;
}
.flexAlignSelfFlexEnd {
  align-self: flex-end;
}
.flexWrap {
  flex-wrap: wrap;
}

.flexDirectionColumnReverse {
  flex-direction: column-reverse;
}
.flexDirectionColumn {
  flex-direction: column;
}

.flexAlignBaseline {
  align-items: baseline;
}
.flexGrow {
  flex-grow: 1;
}
.flexVerticalAlignCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.displayInlineBlock {
  display: inline-block;
}

.displayInherit {
  display: inherit;
}


.centerOnPage80vw {
  position: relative;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.centerOnPage100vw {
  position: relative;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}




.shcMaterialTextField {
  /*border: 1px #e6ebf1 solid !important;*/
  background-color: #fafafa;    /*from: fefefe*/
  background-clip: padding-box !important;
  border-radius: 5px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  word-break: normal !important;
  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
}


.shcMaterialTextField label {
  padding-left: 10px;
  /*transform: translate(0, 8.5px) scale(0.75) !important;*/
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  color: #999 !important;
}
.shcMaterialTextField input {
  padding-left: 10px;
  width:100%;
}
.shcMaterialTextField p[id$="-helper-text"] {
  padding-left: 10px;
  position: absolute;
  bottom: -16px;
}




/****** CALC FUNCTIONS ******/
.fullwidthMinus150px {
  width: calc(100% - 150px);
}



.shcGrid290 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 12px;
  grid-auto-flow: dense;
}

.shcGrid20 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 12px;
  grid-auto-flow: dense;
}

/* Extra-wide grid-posts */
/*
.shcGridItem:nth-child(31n + 1) {
  grid-column: 1 / -1;
}
.shcGridItem:nth-child(16n + 2) {
  grid-column: -3 / -1;
}
.shcGridItem:nth-child(16n + 10) {
  grid-column: 1 / -2;
}
*/

/* Single column display for phones */
@media (max-width: 459px) {
  .shcGridItem {
    display: flex;
    flex-direction: column;
  }
}


.shcGridFixedItem > *{
  padding:30px; color:#fff; font-size:20px; background:#cc0000;
}


@media (max-width: 600px) {
  .shcGridFixed3 {  
    /*display: none;*/
  }
  .shcGridFixed4 {  
    /*display: none;*/
  }
}
@media (min-width: 601px) {
  .shcGridFixed3 {  
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0;
    margin: 0;
    overflow: hidden;
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
  .shcGridFixed4 {  
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0;
    margin: 0;
    overflow: hidden;
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@font-face {
  font-family: "Poppins";
  src: url("https://www.guardpoint.com.au/dist/assets/fonts/Poppins-Regular.eot"),
       url("https://www.guardpoint.com.au/dist/assets/fonts/Poppins-Regular.eot?#iefix") format('embedded-opentype'),
       url("https://www.guardpoint.com.au/dist/assets/fonts/Poppins-Regular.woff2") format('woff2'),
       url("https://www.guardpoint.com.au/dist/assets/fonts/Poppins-Regular.woff") format('woff'),
       url("https://www.guardpoint.com.au/dist/assets/fonts/Poppins-Regular.ttf") format('truetype'),
       url("https://guardpoint.com.au/dist/assets/fonts/Poppins-Regular.eot"),
       url("https://guardpoint.com.au/dist/assets/fonts/Poppins-Regular.eot?#iefix") format('embedded-opentype'),
       url("https://guardpoint.com.au/dist/assets/fonts/Poppins-Regular.woff2") format('woff2'),
       url("https://guardpoint.com.au/dist/assets/fonts/Poppins-Regular.woff") format('woff'),
       url("https://guardpoint.com.au/dist/assets/fonts/Poppins-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "Path";
  src: url("https://www.guardpoint.com.au/dist/assets/fonts/mazeletter-path.woff") format('woff'),
        url("https://www.guardpoint.com.au/dist/assets/fonts/mazeletter-path.woff2") format('woff2'),
        url("https://www.guardpoint.com.au/dist/assets/fonts/mazeletter-path.eot") format('embedded-opentype'),
        url("https://www.guardpoint.com.au/dist/assets/fonts/mazeletter-path.ttf") format('truetype'),
        url("https://www.guardpoint.com.au/dist/assets/fonts/mazeletter-path.svg") format('svg'),
        url("https://guardpoint.com.au/dist/assets/fonts/mazeletter-path.woff") format('woff'),
        url("https://guardpoint.com.au/dist/assets/fonts/mazeletter-path.woff2") format('woff2'),
        url("https://guardpoint.com.au/dist/assets/fonts/mazeletter-path.eot") format('embedded-opentype'),
        url("https://guardpoint.com.au/dist/assets/fonts/mazeletter-path.ttf") format('truetype'),
        url("https://guardpoint.com.au/dist/assets/fonts/mazeletter-path.svg") format('svg');
}



#shc-pattern {
  font-family: "Path";
}

#shc-pattern .pattern {
  width: 100%;
  height: 100%;
  -webkit-transition: height 0.15s 0.15s ease-in-out, opacity 0.15s ease-in-out;
  -moz-transition: height 0.15s 0.15s ease-in-out, opacity 0.15s ease-in-out;
  -ms-transition: height 0.15s 0.15s ease-in-out, opacity 0.15s ease-in-out;
  transition: height 0.15s 0.15s ease-in-out, opacity 0.15s ease-in-out;
} 

#shc-pattern .quilt {
  display: block;
  /*position: fixed;*/
  z-index: 0;
  top: -32px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 88px;
  line-height: 88px;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
  font-family: 'Path';
}

.shcPatternsPath1 {
  font-family: "Path";
  font-size: 60px;
  margin: 0;
  padding: 0;
  line-height: 1;
  overflow: hidden;
}
.shcPatternsAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}
.shcPatternsThemeDark {
  background-color: #343A40;
  color: #222;
}
.shcPatternsThemeGreyE9 {
  background-color: #e9e9e9;
  color: #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
}

.shcPatternsThemeDanger {
  background-color: #c0392b;
  color: #b53528;
}

.shcPatternsThemeBlue {
  background-color: #2980b9;
  color: #2e8ece;
}

.shcPatternsThemeDarkBlue {
  background-color: #123850;
  color: #20638f;
}

.shcPatternsTextAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.shcPatternsFontAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}


 
.consumerBusinessBoxGrey {
  background-color: #f2f3f3;
}
.consumerBusinessBoxWhite {
  background-color: #fff;
}
.consumerBusinessBoxDisplay {
  padding: 30px;
  /*max-width: 100%;*/
}
