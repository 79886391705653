
/*************** AGENT CONFIG ******************/

.ShcModulesAgentConfigContainer {
  padding: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 8px;
  word-wrap: break-word;
}

.ShcModulesAgentConfigContainerHeader {
  background-color: #fafafa;
  color: #000;
  border-bottom: 1px solid #eaeded;
}










/*************** CARD BASIC V2 ******************/

.ShcCardBasicV2ContainerHighlight_primary {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 2px solid #2980b9 !important;
  background-color: rgba(41, 128, 185, 0.1) !important;
}
.ShcCardBasicV2ContainerHighlight_danger {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 2px solid #c0392b;
  background-color: rgba(192, 57, 43, 0.1) !important;
}

.ShcCardBasicV2Container {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  border: 2px solid transparent;  /*even up the highlights*/
}

.ShcCardBasicV2Container_slim {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 1px 8px;
  color: rgba(0, 0, 0, 0.87);
  border: 2px solid transparent;  /*even up the highlights*/
}




/*************** CARD MINI STYLES ******************/
.ShcCardMiniS1Container {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
}

.ShcCardMiniS1Container_section {
  padding: 0.7rem 0.7rem;
}

.ShcCardMiniS1Container_sectionSplit {
  display:flex;
  justify-content: space-between;
}


.ShcCardMiniS1Container_headlineSubTextLeft {
  font-size: 0.9rem;
}
.ShcCardMiniS1Container_headlineSubTextRight {
  font-size: 0.9rem;
}



/*************** DASHBOARD CONTAINERS ******************/
.ShcdashboardContainer {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 5px 0 15px 0;
  color: rgba(0, 0, 0, 0.87);
}
.ShcdashboardContainer_header {
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeded;
  padding: 1.2rem 1.2rem;
  font-weight: 700;
}

.ShcdashboardContainer_section {
  padding: 1.2rem 1.2rem;
}

.ShcdashboardContainer_sectionSplit {
  display:flex;
  justify-content: space-between;
}


.SchdashboardContainer_headlineSubTextLeft {
  font-size: 0.9rem;
}
.SchdashboardContainer_headlineSubTextRight {
  font-size: 0.9rem;
}

.SchdashboardContainer_headlineText {
  font-size: 1.2rem;
  line-height: 2rem;
  /*font-weight: 700;*/
  padding: 1.2rem 0
}

.SchdashboardContainer_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
}
.SchdashboardContainer_secondaryText {
  font-size: 0.8rem;
  line-height: 0.7rem;
  color: #879596;
  margin-bottom: 10px;
}

.SchdashboardContainer_footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}




.ShcdashboardContainerDash2 {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 5px 0 15px 0;
  color: rgba(0, 0, 0, 0.87);
  height: 100%
}
.ShcdashboardContainerDash2_header {
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeded;
  padding: 1.2rem 1.2rem;
  font-weight: 700;
}

.ShcdashboardContainerDash2_lightGrey {
  color: #545b64
}

.ShcdashboardContainerDash2_section {
  padding: 0.7rem 0.7rem;
}

.ShcdashboardContainerDash2_sectionSplit {
  display:flex;
  justify-content: space-between;
}


.ShcdashboardContainerDash2_headlineSubTextLeft {
  font-size: 0.9rem;
}
.ShcdashboardContainerDash2_headlineSubTextRight {
  font-size: 0.9rem;
}

.ShcdashboardContainerDash2_headlineText {
  padding-top:10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
  line-height: 2rem;
  /*font-weight: 700;*/
  /*padding: 1.2rem 0*/
  text-align: center;
}

.ShcdashboardContainerDash2_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
}
.ShcdashboardContainerDash2_secondaryText {
  font-size: 0.9rem;
  color: #879596;
  margin-bottom: 10px;
  text-align: center;
}

.ShcdashboardContainerDash2_footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}









.ShcdashboardContainerDash3 {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*margin-bottom: 2rem;*/
  word-wrap: break-word;
  padding: 15px;
  color: rgba(0, 0, 0, 0.87);
  height: 100%
}
.ShcdashboardContainerDash3_header {
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeded;
  padding: 1.2rem 1.2rem;
  font-weight: 700;
}

.ShcdashboardContainerDash3_lightGrey {
  color: #545b64
}

.ShcdashboardContainerDash3_section {
  padding: 0.7rem 0.7rem;
}

.ShcdashboardContainerDash3_sectionSplit {
  display:flex;
  justify-content: space-between;
}


.ShcdashboardContainerDash3_headlineSubTextLeft {
  font-size: 0.9rem;
}
.ShcdashboardContainerDash3_headlineSubTextRight {
  font-size: 0.9rem;
}

.ShcdashboardContainerDash3_headlineText {
  padding-top:10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
  line-height: 2rem;
  /*font-weight: 700;*/
  /*padding: 1.2rem 0*/
  text-align: center;
}

.ShcdashboardContainerDash3_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
}
.ShcdashboardContainerDash3_secondaryText {
  font-size: 0.9rem;
  color: #879596;
  margin-bottom: 10px;
  text-align: center;
}

.ShcdashboardContainerDash3_footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}









/**** END DASHBOARDS *****/

/**** INPUT CARDS ****/

.ShcInputContainer {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 10, 9, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 10, 9, 0.5);
  border-top: 1px solid #ebedec;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
}
.ShcInputContainer_header {
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: #fafafa;
  border-bottom: 1px solid #ebedec;
  padding: 1.2rem 1.2rem;
  /*font-weight: 700;*/
}
.ShcInputContainer_headerSecondaryText {
  font-size: 14px;
}

.ShcInputContainer_body {
  padding: 1.2rem 1.2rem;
}
.ShcInputContainer_footer {
  
}
.SchInputContainer_inputSection {
  padding: 5px 0 15px 0;
}

.SchInputContainer_headlineText {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 1.2rem 1.2rem;
  /*font-weight: 700;*/
}



.SchInputContainer_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
}
.SchInputContainer_secondaryText {
  font-size: 0.8rem;
  line-height: 0.7rem;
  color: #879596;
  margin-bottom: 10px;
}
.SchInputContainer_infoText {
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #007dbc;
  text-decoration: none;
  font-size:0.9rem;
  margin-left:10px;
}
.SchInputContainer_helpText {
  font-size: 0.8rem;
  color: #879596;
  margin-bottom: 4px;
}
.SchInputContainer_refText {
  font-size: 0.8rem;
  color: #879596;
  margin-bottom: 4px;
  text-align: right;
  font-size: x-small;
}

.SchInputContainer_textinputWrapper {
  width:100%;
  display:inline-block;
  position: relative;
}
.SchInputContainer_textinputWrapper::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f274';
  position: absolute;
  right: 6px;
  top: 20%;
}

.ShcInputContainer_textInput {
  padding: 0.4rem 1rem;
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #aab7b8;
  font-size: 1.1rem;
  line-height: 1.9rem;
  height: 1.9rem;
}




/**** END INPUT CARDS ****/


/**** SINGLE LINE CARD ITEM ****/
.ShcSingleLineCardItem {
  padding: 0;
}

.ShcSingleLineCardItem_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
}
.ShcSingleLineCardItem_refText {
  font-size: 0.8rem;
  color: #879596;
  margin-bottom: 4px;
  text-align: right;
  font-size: x-small;
}


/*border-bottom: 2px solid rgba(0, 132, 191, 1);*/


.shcCardNavigationCard_headlineSubTextPanel {
  display:flex;
  flex-grow: 1;
  justify-content: space-between;
}

.shcCardNavigationCard_headlineSubLeft {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:10px;
}
.shcCardNavigationCard_headlineSubRight {
  color: rgba(0, 0, 0, 0.54);
  font-size:14px;
  
}

.shcCardNavigationCard_headlineText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
}
.shcCardNavigationCard_secondaryText {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:25px;
}
.shcCardNavigationCard_additionalText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.shcCardNavigationCard_container {
  position: relative;
  min-height:250px;

  display: flex;
  flex-direction: column;
}

.shcCardNavigationCard_buttonContainer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}
.shcCardNavigationCard_button {
  color: rgba(0, 132, 191, 1);
}

/******* STATCARD *******/
.shcCardStatCard_headlineSubTextPanel {
  display:flex;
  flex-grow: 1;
  justify-content: space-between;
}

.shcCardStatCard_headlineSubLeft {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:10px;
}
.shcCardStatCard_headlineSubRight {
  color: rgba(0, 0, 0, 0.54);
  font-size:14px;
  
}

.shcCardStatCard_headlineText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
}
.shcCardStatCard_secondaryText {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:25px;
}
.shcCardStatCard_additionalText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.shcCardStatCard_container {
  position: relative;
  min-height:50px;

  display: flex;
  flex-direction: column;
}

.shcCardStatCard_buttonContainer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}
.shcCardStatCard_button {
  color: rgba(0, 132, 191, 1);
}


/****** NavigationPropertyCard_v2 ******/
.NavigationPropertyCard_container {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.NavigationPropertyCard_head {
  height: 90px;
  color:#fff;
  background-color: #16a085;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NavigationPropertyCard_body {
  display:flex;
  flex-direction:row;
  background-color: #EEEEEE;
}
.NavigationPropertyCard_body_sidebar {
  width:70px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.NavigationPropertyCard_body_sidebar_links {

}
.NavigationPropertyCard_body_main {
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.NavigationPropertyCard_body_sidebar_link_item {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
}
.NavigationPropertyCard_body_main_panel {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  width: 100%;
  border-radius: 5px;
  margin-bottom:5px;
}
.NavigationPropertyCard_section {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 10px;
}
.NavigationPropertyCard_sectionContent {
  display: flex;
  font-size: 20px;
  padding: 0 10px;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
  padding: 2px;
}
.NavigationPropertyCard_innerTitle {
  font-size: 12px;
  color: #666666;
}
.NavigationPropertyCard_innerArrow {
  font-size: 14px;
  color: #666666;
}




.NavigationPropertyCard_statCard_container {
  display: flex;
  font-size: 20px;
  /*padding: 0 10px;*/
  justify-content: flex-end;
  flex-direction: column;
  border: 1px solid #eee;
  margin-bottom: 8px;
}
.NavigationPropertyCard_statCard_section {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 10px;
}
.NavigationPropertyCard_statCard_innerTitle {
  font-size: 12px;
  color: #666666;
}
.NavigationPropertyCard_statCard_innerArrow {
  font-size: 14px;
  color: #666666;
  display:none;
}
.NavigationPropertyCard_statCard_sectionContent {
  display: flex;
  font-size: 20px;
  padding: 0 10px;
  justify-content: flex-end;
}

.NavigationPropertyCard_statCard_sectionContent_left {
  display: flex;
  flex-grow: 1;
}
.NavigationPropertyCard_statCard_sectionContent_left_icon {
  padding-right: 5px;
  color: #666666;
}
.NavigationPropertyCard_statCard_sectionContent_left_text {

}
.NavigationPropertyCard_statCard_sectionContent_right {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.NavigationPropertyCard_statCard_sectionContent_right_icon {
  padding-right: 5px;
}
.NavigationPropertyCard_statCard_sectionContent_right_text {

}

/***************** Connect Manually *****************/

.shcCardConnectManuallyCard_headlineSubTextPanel {
  display:flex;
  flex-grow: 1;
  justify-content: space-between;
}

.shcCardConnectManuallyCard_headlineSubLeft {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:10px;
}
.shcCardConnectManuallyCard_headlineSubRight {
  color: rgba(0, 0, 0, 0.54);
  font-size:14px;
  
}

.shcCardConnectManuallyCard_headlineText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
}
.shcCardConnectManuallyCard_secondaryText {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:25px;
}
.shcCardConnectManuallyCard_additionalText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.shcCardConnectManuallyCard_container {
  position: relative;
  min-height:250px;

  display: flex;
  flex-direction: column;
}

.shcCardConnectManuallyCard_buttonContainer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}
.shcCardConnectManuallyCard_button {
  color: rgba(0, 132, 191, 1);
}

/******** BASIC CARD**************/
.CardBasic_wrapper {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.CardBasic_padding20 {
  padding:20px;
}




.gettingStartedCardIcon {
  min-width: 80px;
  font-size: 50px;
  display: flex;
  align-items: center;
}