

.stripeFormContainerRecord {
  display:flex;
  flex-grow: 1;
  flex-direction: column;
}
.fullwidth {
  width:100%;
}
.materialTextFieldNarrow {
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}
.stripeFormFlexGrid {
  display:flex;
  flex-grow:1;
  align-content: space-between;
}
.stripeFormSectionTitle {
  font-size: 18px;
  padding-top:40px;
  padding-bottom:15px;
  color: #444;
}
.stripeFormSectionOptions {
  font-size: 12px;
  color: #444;
}

.shcMaterialTextFieldContainer {
  padding-bottom:21px;
  width: 100%;
  padding-right: 10px;
}


label[for="something"] {
  /* woohoo! */
}

.flexContainerBaseline {
  display:flex;
  justify-content: space-between;
  align-items: baseline;
}
.flexContainerCenter {
  display:flex;
  justify-content: space-between;
  align-items: baseline;
}
.stripeFormPadding {
  padding-right:10px;
}
.stripeFormPaddingButtons {
  width: calc(100% - 10px);
}



/* CUSTOM STYLING */
.flexMargin5 {
  margin-left:5px;
  margin-right:5px;
}
.fullwidth {
  width: 100%;
}
.shcMaterialTextFieldBaselinePadding {
  padding-bottom:21px;
}
.shcMaterialTextFieldMarginNarrow {
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}











