.ShcAccountModulesContainer {
  padding:0;
}
.ShcAccountModulesContainerHeader {
  background-color: #2c3e50;
  color:#fff;
}
.ShcAccountModulesContainerBody {
  padding: 0 20px 20px 20px;
  background-color: #fff;
}
.ShcAccountModulesStatusContainer {
  color:#fff;
  display: flex;
  width:40px;
  justify-content: center;
  align-items: center;
}

.ShcAccountModulesChildContainer {
  padding:0;
}
.ShcAccountModulesChildContainerHeader {
  background-color: #2c3e50;
  color:#fff;
}
.ShcAccountModulesChildContainerBody {
  padding: 0 20px 20px 20px;
  background-color: #fff;
}
.ShcAccountModulesChildStatusContainer {
  color:#fff;
  display: flex;
  width:40px;
  justify-content: center;
  align-items: center;
}





.ShcAccountModulesBusinessConnectContainer {
  padding:0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
}
.ShcAccountModulesBusinessConnectContainerHeader {
  background-color: #ffffff;
  color:#000;
  padding:10px;
  border-bottom: 1px solid #eaeded;
}
.ShcAccountModulesBusinessConnectContainerBody {
  padding: 0 20px 20px 20px;
  background-color: #fff;
}
.ShcAccountModulesBusinessConnectStatusContainer {
  color:#fff;
  display: flex;
  width:40px;
  justify-content: center;
  align-items: center;
}







.UsersRolesDisplay_container {
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
}

.UsersRolesDisplay_titleText {
  text-align: left;
  padding-right:20px;
  font-weight: bold;
}
.UsersRolesDisplay_subTitleText {
  text-align: left;
  font-size:14px;
}

.UsersRolesDisplay_contentText {
  text-align: left;
}
/* DivTable.com */
.UsersRolesDisplay_divTable{
	display: table;
	width: 100%;
}
.UsersRolesDisplay_divTableRow {
	display: table-row;
}
.UsersRolesDisplay_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.UsersRolesDisplay_divTableCell, .UsersRolesDisplay_divTableHead {
	display: table-cell;
	padding: 10px 40px 10px 10px;
}
.UsersRolesDisplay_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.UsersRolesDisplay_divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.UsersRolesDisplay_divTableBody {
	display: table-row-group;
}
.UsersRolesDisplay_TableRowStyles { 
  border-bottom: 1px solid #bbb;
}






/****** PERMISSIONS DISPLAY ********/
.UsersRolesPermissionsDisplay_container {
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
}

.UsersRolesPermissionsDisplay_titleText {
  text-align: right;
  padding-right:20px;
  font-weight: bold;
}
.UsersRolesPermissionDisplay_tableHeader {
  text-align: left;
  font-size:14px;
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 1px solid #bbb;
}
.UsersRolesPermissionDisplay_addControlsText {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  border-top: 1px solid #bbb;
  padding-top: 8px;
}
.UsersRolesPermissionDisplay_contentText {
  text-align: left;
  font-size: 14px;
}

/* DivTable.com */
.UsersRolesPermissionsDisplay_divTable{
	display: table;
	/*width: 100%;*/
}
.UsersRolesPermissionsDisplay_divTableRow {
	display: table-row;
}
.UsersRolesPermissionsDisplay_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.UsersRolesPermissionsDisplay_divTableCell, .UsersRolesPermissionsDisplay_divTableHead {
	display: table-cell;
	padding: 3px 10px;
}
.UsersRolesPermissionsDisplay_divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.UsersRolesPermissionsDisplay_divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.UsersRolesPermissionsDisplay_divTableBody {
  display: table-row-group;
  
  border: 1px solid #bbb;
  padding: 10px;
}
.UsersRolesPermissionDisplay_contentText {
  font-size: 14px;
}


.UsersRolesPermissionDisplay_permissionItemStyle {
  padding-right: 15px;
  cursor: pointer;
  display: flex;

}
.UsersRolesPermissionDisplay_permissionItemStyleRemove {
  color: #bbb;
  cursor: pointer;
  display: none;
  padding-top: 2px;
  padding-left: 5px;
}


.UsersRolesPermissionDisplay_permissionItemStyle:hover .UsersRolesPermissionDisplay_permissionItemStyleRemove, .UsersRolesPermissionDisplay_permissionItemStyle.hover .UsersRolesPermissionDisplay_permissionItemStyleRemove { 
  display: block; 
}

.UsersRolesPermissionDisplay_popupRemovePermissionContainer {
  padding: 20px 10px;
}
.UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle {
  font-size: 26px;
  padding-bottom:15px;
}
.UsersRolesPermissionDisplay_popupRemovePermissionsFooter {
  border-top:1px solid #bbb;
  padding-top: 10px;
}

/****** VERIFY RESULTS PUBLIC ******/
.VerifyResultsPublic_Container {
  padding: 0;
}
.VerifyResultsPublic_Header {
  background-color: #2c3e50;
  padding: 0;
  color: #fff;
}
.VerifyResultsPublic_headerStatusContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  font-size:32px;
}
.VerifyResultsPublic_verifiedBusinessContainer {
  width: 30px;
  text-align: center;
}

.VerifyResultsPublic_text_healthy {
  width: 30px;
  text-align: center;
  color: #27ae60;
}
.VerifyResultsPublic_text_unhealthy {
  width: 30px;
  text-align: center;
  color: #e74c3c;
}
.VerifyResultsPublic_text_questionable {
  width: 30px;
  text-align: center;
  color: #f39c12;
}
.VerifyResultsPublic_text_expired {
  width: 30px;
  text-align: center;
  color: #f39c12;
}


.VerifyResultsPublic_headerOverallStatus {
  width:60px;
  
}
.VerifyResultsPublic_headerOverallStatus_healthy {
  background-color:#27ae60;
  color:#fff;
}
.VerifyResultsPublic_headerOverallStatus_unhealthly {
  background-color:#e74c3c;
  color:#fff;
}
.VerifyResultsPublic_headerOverallStatus_questionable {
  background-color:#f39c12;
  color:#fff;
}
.VerifyResultsPublic_headerOverallStatus_expired {
  background-color:#f39c12;
  color:#fff;
}
