

.ShcModulesIdentityManagementExpandTabbedOption_light {
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  justify-content: center;
  /*border: 1px solid #fff;*/
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1px;

  min-height: 20px;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
}

.ShcModulesIdentityManagementExpandTabbedOptionSelected_light {
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  justify-content: center;
  /*border: 1px solid #fff;*/
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1px;

  min-height: 20px;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #2980b9;
}




















.shctext {
  cursor: pointer;
  border:none;
  display:flex;
  padding: 8px 10px;
  /*min-height: 36px;*/
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  outline:none;
  background: inherit;
  margin:2px;
}

.shctext-tiny {
  height:18px;
  font-size:10px;
  padding: 0 6px;
  padding-top: 3px;
}

.shctext-small {
  height:25px;
  font-size:11px;
  padding: 0 10px;
}

.shctext-medium {
  min-height: 36px;
  font-size:13px;
  padding: 8px 10px; 
}
.shctext-large {
  min-height: 36px;
  font-size:16px;
  padding: 8px 10px; 
}

.shctext-rounded {
  border-radius: 4px;
}




.shctext-primary {
  background-color: #2980b9;
  color: #fff;
}
.shctext-secondary {
  background-color: #34495e;
  color: #fff;
}
.shctext-success {
  background-color: #16a085;
  color: #fff;
}
.shctext-danger {
  background-color: #c0392b;
  color: #fff;
}
.shctext-warning {
  background-color: #e67e22;
  color: #fff;
}
.shctext-info {
  background-color: #8e44ad;
  color: #fff;
}




.shctext-outline-primary {
  border: 1px solid  #2980b9;
  color: #20638f;
}
.shctext-outline-secondary {
  border: 1px solid  #34495e;
  color: #22303d;
}
.shctext-outline-success {
  border: 1px solid  #16a085;
  color: #107360;
}
.shctext-outline-danger {
  border: 1px solid  #c0392b;
  color: #962d22;
}
.shctext-outline-warning {
  border: 1px solid  #e67e22;
  color: #bf6516;
}
.shctext-outline-info {
  border: 1px solid  #8e44ad;
  color: #703688;
}





.shctext-default-primary {
  color: #20638f;
}
.shctext-default-secondary {
  color: #22303d;
}
.shctext-default-success {
  color: #107360;
}
.shctext-default-danger {
  color: #962d22;
}
.shctext-default-warning {
  color: #bf6516;
}
.shctext-default-info {
  color: #703688;
}







.shctext-default-soft-primary {
  color: #20638f;
}
.shctext-default-soft-secondary {
  color: #22303d;
}
.shctext-default-soft-success {
  color: #107360;
}
.shctext-default-soft-danger {
  color: #962d22;
}
.shctext-default-soft-warning {
  color: #bf6516;
}
.shctext-default-soft-info {
  color: #703688;
}


/**/






