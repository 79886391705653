.ShcStepsContainer {
  padding:0;
}
.ShcStepsContainerHeader {
  background-color: #2c3e50;
  color:#fff;
}
.ShcStepsContainerBody {
  padding: 0 20px 20px 20px;
  background-color: #fff;
}
.ShcStepsStatusContainer {
  color:#fff;
  display: flex;
  width:40px;
  justify-content: center;
  align-items: center;
}
.footer-buttons {
  padding: 8px 2px;
  display:flex;
  justify-content: space-between;
}

.ShcStepsNextButton {

}
.ShcStepsBackButton {

}

ol.progtrckr {
  list-style-type: none;
  padding: 0;

  display: flex;
  justify-content: space-between;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;

  flex-grow: 1;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: #ccc;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
  color: #ccc;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  /*bottom: -3.7rem;*/
  bottom: -4rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  /*content: "\039F";*/
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  font-family: "Font Awesome 5 Free";
  content: "\f192";
  /*content: "\2022";*/
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  /*content: "\2713";*/
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}