


.subscriptionFeaturesPageWrapper {
  max-width:1058px;
  margin-left: auto;
  margin-right: auto;
}
.subscriptionFeaturesMessageOtherPlans {
  display:flex;
  justify-content: flex-end;
  font-size:14px;
  color: #757575;
}
.contentPageAdditionalLinks {
  text-align:right;
}


.contentPageHeadlineContainer {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
}
.contentPageHeadline {
  font-size:30px;
  color:#424242;
}
.contentPageSecondline {
  font-size:18px;
  color:#424242;
}

.subscriptionFeaturesHeadlineProducts {
  font-size:14px;
  text-align:center;
}

.subscriptionFeaturesBaselineProducts {
  font-size: 20px;
  color: #757575;
  padding-top: 66px;
  min-height: 100px;

  display: flex;
  align-items: flex-end;
}
.subscriptionFeaturesHeadlineSectionTitle {
  font-size: 20px;
  color: #757575;
  padding-top: 66px;
  min-height: 100px;
}
.subscriptionFeaturesHeadlineTitle {
  font-size: 18px;
  color:#fff;
  padding-bottom:10px;
}
.subscriptionFeaturesHeadlineSecondary {
  font-size: 14px;
  color:#fff;
  padding-bottom:10px;
}
.subscriptionFeaturesHeadlinePrice {
  font-size: 26px;
  color:#fff;
}

.subscriptionFeaturesPrimaryText {
  color: #212121;
}

.bgLightGrey {
  background-color: #f5f5f5;
}
.bgLightGreyContent {
  background: rgba(255,255,255,.95);
}
.bgBlueLight {
  background-color: #4fc3f7;
}
.bgBlueLightContent {
  background-color: rgba(79,195,247,.05);
}
.bgBlueMedium {
  background-color: #039be5;
}
.bgBlueMediumContent {
  background-color: rgba(30,136,229,.05);
}
.bgBlueDark {
  background-color: #3c587c;
}
.bgBlueDarkContent {
  background-color: rgba(60,88,124,.05);
}
.subscriptionFeaturesContainers {
  padding: 20px;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.subscriptionFeaturesBaselineContainers {
  padding: 20px;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display:flex;
  align-items: flex-start;
}
.subscriptionFeaturesBaselineContainerLayout {
  flex-direction: row;
  flex-grow: 1;
}
.subscriptionFeaturesWrapper {
  max-width:1058px;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  margin-left: auto;
  margin-right: auto;
}
.subscriptionFeaturesContainersCenterHV {
  display: flex;
  justify-content: center;
  align-items: center;
}




.subscriptionFeaturesItemTitle {
  font-size: 16px;
  /*color: #ffa000;*/
  color: #444;
  font-weight: bold;
  padding-bottom: 10px;
}
.subscriptionFeaturesItemText {
  font-size: 14px;
  color: #757575;
}





