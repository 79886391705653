/**** SHCPOPUPS ****/
.OverlayScreenContainerBasePosition {
  right:-100vw;

}

.OverlayScreenContainer {
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6000;
}
.OverlayBaseBg {
  position: absolute;
  z-index:5000;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: rgba(0,0,0,.6);
  display:flex;
  justify-content: center;


}


.OverlayOnScreen-enter {
  opacity: 0.01;
}
.OverlayOnScreen-enter.OverlayOnScreen-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
.OverlayOnScreen-leave {
  opacity: 1;
}

.OverlayOnScreen-leave.OverlayOnScreen-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}



.OverlayOnScreen-appear {
  opacity: 0.01;
}

.OverlayOnScreen-appear.OverlayOnScreen-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}




.OverlayOnScreen_Container {
  position: absolute;
  background-color: #fff;
  right:0;
}

.OverlayOnScreen_Header {
  background-color: #2980b9;
  color: #fff;
  font-weight: 500;
  display: flex;
  -ms-flex-align: start;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecef;
  overflow-wrap: break-word;
  height:45px;
  padding: 0 15px 0 15px;
}

.OverlayOnScreen_Header_theme1 {
  background-color: #2980b9;
}
.OverlayOnScreen_Header_theme2 {
  background-color: #343A40;
}



.OverlayOnScreen_HeaderTitle {

}
.OverlayOnScreen_HeaderClose {
  cursor: pointer;
}
.OverlayOnScreen_ContainerContent {

}









.OverlayOnScreenResponsive_Container {
  position: absolute;
  background-color: #fff;
  right:0;
}

.OverlayOnScreenResponsive_Header {
  background-color: #2980b9;
  color: #fff;
  font-weight: 500;
  display: flex;
  -ms-flex-align: start;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecef;
  overflow-wrap: break-word;
  height:45px;
  padding: 0 15px 0 15px;
}

.OverlayOnScreenResponsive_Header_theme1 {
  background-color: #2980b9;
}
.OverlayOnScreenResponsive_Header_theme2 {
  background-color: #343A40;
}

.OverlayOnScreenResponsive_HeaderTitle {

}
.OverlayOnScreenResponsive_HeaderClose {
  cursor: pointer;
}
.OverlayOnScreenResponsive_ContainerContent {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.OverlayOnScreenResponsive_Container_defaultStyle {
  transition: width 2s ease 0s;
  position:relative;
  margin-top: 55px;
  margin-left:auto;
  margin-right:auto;
  width:600px;
  max-width:100vw;
}

.OverlayOnScreenResponsive-enter {
  opacity: 0.01;
}
.OverlayOnScreenResponsive-enter.OverlayOnScreenResponsive-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
.OverlayOnScreenResponsive-leave {
  opacity: 1;
}
.OverlayOnScreenResponsive-leave.OverlayOnScreenResponsive-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}

.OverlayOnScreenResponsive-appear {
  opacity: 0.01;
}
.OverlayOnScreenResponsive-appear.OverlayOnScreenResponsive-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}







.OverlayOnScreenResponsiveLoading_Container {
  position: absolute;
  background-color: #fff;
  right:0;
}
.OverlayOnScreenResponsiveLoading_Container_defaultStyle {
  transition: width 2s ease 0s;
  position:relative;
  margin-top: 55px;
  margin-left:auto;
  margin-right:auto;
  max-width:100vw;
  border-radius: 10px;
}
.OverlayOnScreenResponsiveLoading_ContainerContent {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}


















