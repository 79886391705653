.ShcTextMessageTip {
  background-color: inherit;
  border-left: 3px solid rgb(247, 190, 74);
  padding-left: 10px;
  font-size: 13px;
  margin: 3px;
  flex-grow: 1;
  min-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #999;
}

.ShcTextMessageHighlight {
  background-color: inherit;
  border-left: 3px solid rgb(41, 128, 185);
  padding-left: 10px;
  font-size: 13px;
  margin: 3px;
  flex-grow: 1;
  min-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ShcTextInfoCopyLabelContainer {
  background-color: #efefef;
  border-left: 3px solid rgb(41, 128, 185);
  padding-left: 10px;
  font-size: 13px;
  margin: 3px;
  min-height: 15px;
  border-radius: 10px;
}
.ShcTextInfoCopyLabelSubHeaderLeft {
  padding-top: 5px;
  flex-grow: 1;
}
.ShcTextInfoCopyLabelSubHeaderRight {
  padding-top: 5px;
  padding-right: 10px;
}
.ShcTextInfoCopyLabelContent {
  font-size: 30px;
}
.ShcTextInfoCopyLabelControls {
  width: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/***** Highlight Container *****/
.ShcHighlightContainerContainer {
  background-color: #efefef;
  border-left: 3px solid rgb(41, 128, 185);
  padding-left: 10px;
  font-size: 13px;
  margin: 3px;
  min-height: 15px;
  border-radius: 10px;
}
.ShcHighlightContainerSubHeaderLeft {
  padding-top: 5px;
  flex-grow: 1;
}
.ShcHighlightContainerSubHeaderRight {
  padding-top: 5px;
  padding-right: 10px;
}
.ShcHighlightContainerContent {

}
.ShcHighlightContainerControls {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}










.shctext-slim {
  cursor: pointer;
  border:none;
  display:flex;
  padding: 0px 10px;
  /*min-height: 36px;*/
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  outline:none;
  background: inherit;
  margin:2px;
}

.shctext {
  cursor: pointer;
  border:none;
  display:flex;
  padding: 8px 10px;
  /*min-height: 36px;*/
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  outline:none;
  background: inherit;
  margin:2px;
}

.shctext-fullwidth {
  width:100%;
}
.shctext-fullwidth div {
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}

.shctext-tiny {
  height:18px;
  font-size:10px;
  padding: 0 6px;
  padding-top: 3px;
}

.shctext-small {
  height:25px;
  font-size:11px;
  padding: 0 10px;
}

.shctext-medium {
  min-height: 36px;
  font-size:13px;
  padding: 8px 10px; 
}
.shctext-large {
  min-height: 36px;
  font-size:16px;
  padding: 8px 10px; 
}
.shclogo-large {
  font-size:28px;
  padding: 0px 10px !important;
}

.shctext-rounded {
  border-radius: 4px;
}

.shctext-primary {
  background-color: #2980b9;
  color: #fff;
}
.shctext-secondary {
  background-color: #34495e;
  color: #fff;
}
.shctext-success {
  background-color: #16a085;
  color: #fff;
}
.shctext-danger {
  background-color: #c0392b;
  color: #fff;
}
.shctext-warning {
  background-color: #e67e22;
  color: #fff;
}
.shctext-info {
  background-color: #8e44ad;
  color: #fff;
}
.shctext-white {
  background-color: #fff;
  color: #000;
}

.shctext-headerLinks {
  color: #444;
}



.shctext-outline-primary {
  border: 1px solid  #2980b9;
  color: #20638f;
}
.shctext-outline-secondary {
  border: 1px solid  #34495e;
  color: #22303d;
}
.shctext-outline-success {
  border: 1px solid  #16a085;
  color: #107360;
}
.shctext-outline-danger {
  border: 1px solid  #c0392b;
  color: #962d22;
}
.shctext-outline-warning {
  border: 1px solid  #e67e22;
  color: #bf6516;
}
.shctext-outline-info {
  border: 1px solid  #8e44ad;
  color: #703688;
}





.shctext-default-primary {
  color: #20638f;
}
.shctext-default-secondary {
  color: #22303d;
}
.shctext-default-success {
  color: #107360;
}
.shctext-default-danger {
  color: #962d22;
}
.shctext-default-warning {
  color: #bf6516;
}
.shctext-default-info {
  color: #703688;
}
.shctext-default-white {
  color: #fff;
}







.shctext-default-soft-primary {
  color: #20638f;
}
.shctext-default-soft-secondary {
  color: #22303d;
}
.shctext-default-soft-success {
  color: #107360;
}
.shctext-default-soft-danger {
  color: #962d22;
}
.shctext-default-soft-warning {
  color: #bf6516;
}
.shctext-default-soft-info {
  color: #703688;
}


.fontSize10px {
  font-size: 10px;
}
.fontSize12px {
  font-size: 12px;
}
.fontSize14px {
  font-size: 14px;
}

.displayInlineBlock {
  display: inline-block;
}


/**/






