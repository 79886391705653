

.shcModulesBusinessReporting_graphWrapper {
  width: 100%;
  height: 450px;
  font-size: 11px;
  min-width: 0;
}
.shcModulesBusinessReporting_containerHeight {
  min-height: 200px;
  height: auto;
}
