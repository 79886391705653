.shcModulesConnectCard_container {
  position: relative;
  min-height:250px;

  display: flex;
  flex-direction: column;
}

.shcModulesConnectCard_headlineSubTextPanel {
  display:flex;
  flex-grow: 1;
  justify-content: space-between;
}
.shcModulesConnectCard_headlineText {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
}
.shcModulesConnectCard_headlineSubRight {
  color: rgba(0, 0, 0, 0.54);
  font-size:14px;
  
}
.shcModulesConnectCard_headlineSubLeft {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom:10px;
}
.shcModulesConnectCard_buttonContainer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}

.shcModulesConnectCard_notVerifiedText {
  color: #c0392b;
  font-size: 0.8rem;
  padding: 0px 8px;
}
.shcModulesConnectCard_notVerifiedIcon {
  color: #c0392b;
  font-size: 18px;
}



.ShcModulesBusinessConnectInteractionItemViewContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-top: 0px;
  max-width: 250px;
}

.ShcModulesBusinessConnectInteractionItemExpandContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
}

.ShcModulesBusinessConnectInteractionItemLeftBorder_Verified {
  border-left: 8px solid #16a085;
}
.ShcModulesBusinessConnectInteractionItemLeftBorder_ActionRequired {
  border-left: 8px solid #d35400;
}
.ShcModulesBusinessConnectInteractionItemLeftBorder_Issues {
  border-left: 8px solid #c0392b;
}

.ShcModulesBusinessConnectInteractionExpiredBackground {
  background-image: repeating-linear-gradient(-45deg,
    transparent,
    transparent 5px,
    #ddd 6px,
    #ddd 3px);
}


.ShcModulesBusinessConnectInteractionItemAddViewContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-top: 0px;
  max-width: 250px;
  height: 40px;
  cursor: pointer;
}