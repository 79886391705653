

.webImageSettings {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 230px) {
  .primaryImage0_330 {
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }

 
 
  

  .webImageSettings {   /*Backup*/
    display: none;
    width: 900%;
    height: auto;
  }
}
@media only screen and (min-width: 230px) and (max-width: 330px) {
  .primaryImage0_330 {
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }

  




  .webImageSettings {
    display: none;
    width: 600%;
    height: auto;
  }
}
@media only screen and (min-width: 330px) and (max-width: 430px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }



  
  
  .webImageSettings {
    display: none;
    width: 400%;
    height: auto;
  }
}
@media only screen and (min-width: 430px) and (max-width: 530px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }


  
  .webImageSettings {
    display: none;
    width: 300%;
    height: auto;
  }
}
@media only screen and (min-width: 530px) and (max-width: 700px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }



  
  .webImageSettings {
    display: none;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 700px) and (max-width: 830px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }



  
  .webImageSettings {
    display: none;
    width: 180%;
    height: auto;
  }
}
@media only screen and (min-width: 830px) and (max-width: 1060px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }



  
  .webImageSettings {
    display: none;
    width: 150%;
    height: auto;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1250px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    display: none;
    width: 100%;
    height: auto;
  }




  
  
  .webImageSettings {
    display: none;
    width: 120%;
    height: auto;
  }
}

@media only screen and (min-width: 1250px) {
  .primaryImage0_330 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage330_700 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage700_1250 {
    display: none;
    width: 100%;
    height: auto;
  }
  .primaryImage1250plus {
    width: 100%;
    height: auto;
  }






  .webImageSettings {
    display: none;
    width: 100%;
    height: auto;
  }
}


.contentContainer:hover {
  border: 2px solid #615CFD;
}
.contentContainer {
  border: 1px solid #3498db;
  border-radius: 5;

  padding: 48px 35px 45px 35px;
  border: 2px solid #E8E8E8;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.contentEdgeToEdgeContainer {
  width: 100vw;
}


.contentContainerImageMax450 {
  max-height: 450px;
  overflow: hidden;
}
.contentContainerRoundedImageOnLeft {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.contentImage {
  width: 100%;
  height: auto;
}

















.gradientBg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
