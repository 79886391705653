.ShcMenuItem {
  font-size:14px;
}
.ShcMenuItemHeaderContainer {
  display:flex;
  flex-direction: column;
}
.ShcMenuItem_Name {
  font-size:16px;
}
.ShcMenuItem_Email {
  font-size:12px;
}

.ShcMenuItem_Button {
  min-width: 60px;
}