


.ShcBusinessDashboardCard_dashboardContainer {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  padding: 5px 0 15px 0;
  color: rgba(0, 0, 0, 0.87);
}

.ShcBusinessDashboardCard_sectionTitle {
  font-size: 1.2rem;
  line-height: 2rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 8px;
}

.ShcBusinessDashboardCard_contentSection {
  padding: 0.5rem 1.2rem;
}


.ShcBusinessDashboardCard_displayTypeHeadline {
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeded;
  padding: 1.2rem 1.2rem;
  text-align: center;
}



.ShcBusinessDashboardCard_footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}
.ShcBusinessDashboardCard_footerSmallPrint {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-end;
}

.ShcBusinessDashboardCard_flexStart {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: flex-start;
}

.ShcBusinessDashboardCard_flexBetween {
  display: flex;
  align-items: space-between;
  flex-grow: 1;
  padding: 8px 12px;
  justify-content: space-between;
}







