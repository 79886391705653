

.smartSelectWrapper {
  width:100%;
  display:flex;
  position: relative;
  padding-bottom: 4px;
}

.smartSelectWrapper::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.smartSelectWrapper:hover::before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.smartSelectWrapper:focus-within::before {
  border-bottom: 2px solid rgba(0, 132, 191, 1);
}



/*
.smartSelectWrapper::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #1976d2;
  pointer-events: none;
}
*/

.smartSelectExpander.smartSelectExpanded:before {
  width: 100%;
  transition: width 0.5s;
  border-bottom: 2px solid rgba(0, 132, 191, 1);
}
.smartSelectExpander:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  width:100%;

  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  pointer-events: none;
  /*background-color: #0084bf;*/
}



.smartSelectChips {
  display:flex;
}
.smartSelectText {
  flex-grow:1;
  margin-left:8px;
  margin-right:8px;
  padding-left:5px;
  border-left:1px solid #ddd;
}
.smartSelectTextStyle {
  width:100%;
  padding-bottom: 11px;
  border: none;
  outline: 0;
}
.smartSelectTextStyle::before {
  border-bottom: none;
}
.smartSelectControls {
  border-left:1px solid #efefef;
  color:#efefef;
  font-size:16px;
  padding-left:8px;
  cursor: pointer;
}
.smartSelectChipItem { 
  background-color: #efefef;
  border-radius: 4px;
  display:flex;
  padding:3px;
  margin-right: 5px;
}
.smartSelectChipItemText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:block;
  width:100%;
  min-width:1px;
}
.smartSelectChipItemIcon {
  margin-left:4px;
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
}
.smartSelectChipsPopupView {

}



.smartSelectChipPopupIcon { 
  background-color: #efefef;
  border-radius: 4px;
  display:flex;
  justify-content: center;
  flex-grow: 1;
  padding:6px;
  margin-right: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 24px;
  align-items: center;
}



.hidden {
  display:none;
}
.hidden_ruleMinChipsInPopup {
  display:none;
}


.smartSelectWrapperValueList {
  position: absolute;
  z-index: 6000;
  top: calc(100% + 4px);
  width:100%;
  border-radius: 4px;
  border: 1px solid #efefef; 
  background-color:#eee;
  padding:2px;
  overflow-y: scroll;
  max-height: 160px;
}
.smartSelectValueListCollection {
  list-style: none;
  padding:0;
  margin:0;
  width:100%;
}
.smartSelectValueListItem {
  width:100%;
  background-color: #eee;
  cursor: pointer;
}
.smartSelectValueListItem:hover {
  background-color: #ddd;
}
.smartSelectValuelistItemActive {
  background-color: #ddd;
}

.smartSelectValueListItem_displayContainer {
  display:flex;
  justify-content: space-between;
}
.smartSelectValueListItem_displaySubContainer {
  display:flex;
}




  .smartSelectWrapperChipPopupList {
    position: absolute;
    z-index: 6001;
    top: calc(100% + 4px);
    width:100%;
    border-radius: 4px;
    border: 1px solid #efefef; 
    background-color:#fff;
    padding:2px;
    overflow-y: scroll;
    max-height: 160px;
  }
  .smartSelectChipPopupListCollection {
    list-style: none;
    padding:0;
    margin:0;
    width:100%;
  }
  .smartSelectChipPopupListCollection li {
    display: inline-block;
    margin-bottom: 4px;
  }




.smartSelectLoadingNotice {
  color:#2980b9;
  font-size:20px;
  padding-right:8px;
  cursor: pointer;
}
.smartSelectErrorNotice {
  color:#e74c3c;
  font-size:20px;
  padding-right:8px;
  cursor: pointer;
}