


.shcPublicHeader_AppInnerBar {
  display: flex;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #444;

  width: 100%;
  flex-shrink: 0;
  flex-direction: column;

  min-height: 45px;
  height:45px;  /* fix for safari not showing */

}




























/**** SHC HEADER ****/

.shcPublicHeader_parentSidebarMain {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;
}
.shcPublicHeader_parentSidebarSmall {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}






@media (max-width: 659px) {
  .shcPublicHeader_parentSidebarPermanent {
    display:none;
  }
  .shcPublicHeader_parentSidebarTemporary {
    display:flex;
  }

  .shcPublicHeader_parentSidebarPermanentOpen {
    width:240px;
  }
  .shcPublicHeader_parentSidebarPermanentClose {
    width:72px;
  }

  .shcPublicHeader_parentSidebarTemporaryOpen {
    width:240px;
  }
  .shcPublicHeader_parentSidebarTemporaryClose {
    width:0;
  }

}
@media (min-width: 660px) {
  .shcPublicHeader_parentSidebarPermanent {
    display:flex;
  }
  .shcPublicHeader_parentSidebarTemporary {
    display:none;
  }

  .shcPublicHeader_parentSidebarPermanentOpen {
    width:240px;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
  .shcPublicHeader_parentSidebarPermanentClose {
    width:72px;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .shcPublicHeader_parentSidebarTemporaryOpen {
    width:240px;
  }
  .shcPublicHeader_parentSidebarTemporaryClose {
    width:72px;
  }
}


.shcPublicHeader_appbarContainer {
  display: flex;
  align-items: center;
  padding: 0;
  height:45px;
}












.shcPublicHeader_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #2980b9;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*/

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  min-height: 45px;
}



.shcPublicHeader_toolbarDrawer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  height: 45px;
  color: #a7b1c2;

}

.shcPublicHeader_iconContainer {
  width:72px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.shcPublicHeader_NavIcon {
  font-size: 20px;
}
.shcPublicHeader_Title {
  font-size: 20px;
}

/***** SIDE BAR COMPONENT *****/
.shcPublicHeader_drawerPaper {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;

  padding-top: 40px;
}
.shcPublicHeader_drawerPaperClose {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

.shcPublicHeader_navigationSideBar {
  color: #a7b1c2;
  overflow: auto;
  overflow-x: hidden;
  height:100%;
}



.shcPublicHeader_drawerPaperTemporary {
  position: absolute;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;
}

.shcPublicHeader_drawerPaperTemporaryClose {
  overflow-x: hidden;
  /*transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;*/
  width: 0;
}
.shcPublicHeader_contentSection {
  min-height: 90vh;
}
.shcPublicHeader_contentPadding {
  /*padding: 24px; Moved to Child to manage*/
  width:100%;
}
/*Support fixed screen view*/
.shcPublicHeader_fixedView {
  width:100%;
  height: 100vh;
}
.hide {
  display: none !important;
}
.shcPublicHeader_sidebarTrackHorizontal {
  display:none;
}
.shcPublicHeader_navBarControls {
  display:flex;
  flex-grow:1;
  justify-content:flex-end;
}


@media (max-width: 959px) {
  .shcPublicHeader_contentMarginPermanent {
    margin-left:0;
  }
  .shcPublicHeader_contentMarginOpen {
    margin-left: 0;
  }
}
@media (min-width: 960px) {
  .shcPublicHeader_contentMarginPermanent {
    margin-left:72px;
  }
  .shcPublicHeader_contentMarginOpen {
    margin-left: 240px;
  }
}

/************* SITE HEADER SUB PANEL **************/


.shcPublicHeaderSubPanel {
  display: flex;
  flex-grow: 1;
  height: 45px;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.shcPublicHeaderSubPanel_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #495057;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*/

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  margin-top: 45px;
  min-height: 45px;
}
.shcPublicHeaderSubPanel_toolbar {
  display: flex;
  align-items: center;
  padding: 0;
  /*height:45px;*/
}
.shcPublicHeaderSubPanel_contentContainer {
  padding: 0;
  width: 100%;
}

@media (max-width: 959px) {
  .shcPublicHeaderSubPanel_contentMarginPermanent {
    margin-left:0;
  }
}
@media (min-width: 960px) {
  .shcPublicHeaderSubPanel_contentMarginPermanent {
    margin-left:72px;
  }
}

/*********** SIDE BAR SUB NAV ************/
/*
.SidebarSubNav_panel {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
  flex-grow: 1;
}
*/
/*
.hide {
  display:none;
}

.SidebarSubNav_foldContent {
  align-items: stretch;
  flex-grow:1;
  background-color: inherit;
  /*border: 2px solid rgb(241, 243, 246);*//*
  padding: 20px;
}
*/