.narrowSideBarContent {
  width:64px;
  text-align: center;
  margin-left:1px;
  margin-right:7px;
}
.navLinkSecondaryText {
  font-size:12px;
  text-align: center;
}
.navLinkSecondaryTextChildren {
  font-size:10px;
  text-align: center;
}

.extendedSideBarTitle {
  font-size:14px;
}
.collapsedSideBarTitle {
  font-size:12px;
}
.extendedSideBarContentCollapse {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}


.divLineDropDownIcon {
  position:relative; /* Position must be relative to absolutely position :after */
  width:calc(100% - 3px);
  border-top:1px solid #a7b1c2;
  margin-left:3px;
  margin-top: 4px;
}

.divLineDropDownIcon:after{
  content:''; /* Required to display content */
  position: absolute; /* Sets the position absolute to the top div */
  top: 0; 
  left: 50%;
  margin-left: -7px; /* Set margin equal to border px */
  width: 0;
  z-index:1;
  height: 0;
  border-top: solid 5px #a7b1c2; /* Creates the notch */
  border-left: solid 5px transparent; /* Creates triangle effect */
  border-right: solid 5px transparent; /* Creates triangle effect */
}





.listItemIcon {
  flex-shrink: 0;
  margin-left: 21px;
  margin-right: 34px;
}

.dividerLineCollapse {
  border-bottom:1px solid rgba(167,177,194, 0.2);
}


.hide {
  display:none;
}
.navigationLinks {
  color: #a7b1c2;
  font-size: 14px;
  flex-grow: 1;
}
.navigationLinksChild {
  font-size: 12px;
}

.listItem {
  width: 250px; /*larger than the expanded view*/
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
}
.listItemMax {
  width: 250px;
}
.listItem:hover {
  /*background-color:#253340;*/
  background-color:#2b3035;
}

.listItemParent {
  padding-top:10px;
  padding-bottom:10px;
}

.listItemChild {
  /*padding-left:15px;
  background-color:#2a3948;
  padding-top:8px;
  padding-bottom:8px;*/
  margin-top:3px;
  margin-bottom:3px;
  padding-top:8px;
  padding-bottom:8px;
  background-color:#2a3948;
  border-left:3px solid #2980b9;
  text-align: left;
}


.listItemIconChild {
  /*flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;*/
}

.listItemExpandArrow {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  padding-right:30px;
}