.shcMenuContainer {
  position: relative;
}
.shcMenu {
  position: absolute;
  padding-top:8px;
  padding-bottom:8px;
}
.shcMenuSimpleDropdown {
  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  color:#000;
}
.shcMenuSimpleDropdownMinWidth {
  width: 220px;
}

.shcMenuSimpleDropdownItem:hover {
  background-color: #ddd;
}
.shcMenuSimpleDropdownItem {
  display:flex;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 40px;
}

shcMenuSimpleDropdownItem_divide {
  margin-left: -15px;
  margin-right: -15px;
}

.shcMenuSimpleDropdownFormatIcons {
  
}

.shcMenuSimpleDropdownItem_icon {
  max-width:50px;
  padding-right:15px;
  display:flex;
  align-items: center;
}
.shcMenuSimpleDropdownItem_text {
  display:flex;
  align-items: center;
}

.shcMenuPositionLeft {
  right:4px;
}