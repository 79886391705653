.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 700px;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: none;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;

  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  /*border: 1px #e6ebf1 solid !important;*/
  background-clip: padding-box !important;
  border-radius: 5px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  word-break: normal !important;
}