/**** SHC HEADER ****/

.shcHeader {
  display: flex;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.shcHeaderContentContainer {
  min-height:90vh;
  margin-top:45px;
}
.shcHeaderContentContainerWidth {
  width:100%;
}
/*
.shcHeader_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #2980b9;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*//*

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  min-height: 45px;
  height:45px;  /* fix for safari not showing *//*
}
*/

.shcHeader_toolbar {
  display: flex;
  align-items: center;
  padding: 0;
  height:45px;
}

.shcHeader_toolbarDrawer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  height: 45px;
  color: #a7b1c2;

}
/*
.shcHeader_iconContainer {
  width:72px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
*/
.shcHeader_NavIcon {
  font-size: 20px;
}
.shcHeader_Title {
  font-size: 20px;
}

/***** SIDE BAR COMPONENT *****/
.shcHeader_drawerPaper {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;

  padding-top: 40px;
}
.shcHeader_drawerPaperClose {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

.shcHeader_navigationSideBar {
  color: #a7b1c2;
  overflow: auto;
  overflow-x: hidden;
  height:100%;
}



.shcHeader_drawerPaperTemporary {
  position: absolute;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;
}

.shcHeader_drawerPaperTemporaryClose {
  overflow-x: hidden;
  /*transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;*/
  width: 0;
}
.shcHeader_contentSection {
  min-height: 90vh;
}
.shcHeader_contentPadding {
  /*padding: 24px; Moved to Child to manage*/
  width:100%;
}
/*Support fixed screen view*/
.shcHeader_fixedView {
  width:100%;
  height: 100vh;
}
.hide {
  display: none !important;
}
.shcHeader_sidebarTrackHorizontal {
  display:none;
}
.shcHeader_navBarControls {
  display:flex;
  flex-grow:1;
  justify-content:flex-end;
}


@media (max-width: 959px) {
  .shcHeader_contentMarginPermanent {
    margin-left:0;
  }
  .shcHeader_contentMarginOpen {
    margin-left: 0;
  }


}
@media (min-width: 960px) {
  .shcHeader_contentMarginPermanent {
    margin-left:72px;
  }
  .shcHeader_contentMarginOpen {
    margin-left: 240px;
  }


}


/*** NEW ***/
/*************** HANDLING HIDDEN **************/
@media (max-width: 460px) {
  .SidebarSubNav_display {
    display: block;
  }
  
}
@media (max-width: 959px) {
  .SidebarSubNav_display {
    display: block;
  }
}
@media (min-width: 960px) {
  .SidebarSubNav_display {
    display: block;
  }
}


.SidebarSubNav_navigation_overlayContainer_small {
  position: absolute;
  background: white;
  z-index: 999;
  border-bottom: 3px solid #999;
  padding-bottom: 20px;
  left:0;
  right:0;
}

.SidebarSubNav_navigation_expanded_small {
  width:100%;
}




/************* SITE HEADER SUB PANEL **************/


.shcHeaderSubPanel {
  display: flex;
  flex-grow: 1;
  height: 45px;
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.shcHeaderSubPanelContainerHeight {
  height: 45px;
}

.shcHeaderSubPanel_AppBar {
  display: flex;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #495057;

  top: 0;
  left: auto;
  right: 0;
  position: fixed;  /*updated from absolute on 19/08/18*/

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  margin-top: 45px;
  min-height: 45px;
}
.shcHeaderSubPanel_toolbar {
  display: flex;
  align-items: center;
  padding: 0;
  /*height:45px;*/
}
.shcHeaderSubPanel_contentContainer {
  padding: 0;
  width: 100%;
}

@media (max-width: 959px) {
  .shcHeaderSubPanel_contentMarginPermanent {
    margin-left:0;
  }
}
@media (min-width: 960px) {
  .shcHeaderSubPanel_contentMarginPermanent {
    margin-left:72px;
  }
}

/*********** SIDE BAR SUB NAV ************/
.SidebarSubNav_panel_small {
  height: 50px;
}
.SidebarSubNav_panel_large {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
  flex-grow: 1;
}

.SidebarSubNav_navigation_small {
  align-items: stretch;
  /*background: rgb(241, 243, 246);*/
  background-color:#fefefe;
}
.SidebarSubNav_navigation_container {
  align-items: stretch;
  /*background: rgb(241, 243, 246);*/
  background-color:#fefefe;
  min-height: 90vh;               /*Added on 20/07/19 to fix short nav bar */
}

.SidebarSubNav_navigation_container_small {
  width: 72px;
}

.SidebarSubNav_navigation_container_large {
  width: 250px;
}

.SidebarSubNav_navIconContainer {
  width: 60px;
  height:50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.SidebarSubNav_navIconContainerAndText {
  height:50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

/*********** SIDE BAR SUB NAV BACK ************/
.SidebarSubNavBack_navigationLinks {
  color: #3F3F3F /*#2a3948*/;
  font-size: 14px;
  flex-grow: 1;
}
.SidebarSubNavBack_navigationLinkHighlight {
  color: #2980b9;
  background-color: #fff;
  width: inherit;
}
.SidebarSubNavBack_listItem {
  width: 250px; /*larger than the expanded view*/
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
}
.SidebarSubNavBack_listItemParent {
  padding-top:10px;
  padding-bottom:10px;
  width: inherit;
}
.SidebarSubNavBack_listBackItem {
  display: flex;
  flex-grow: 1;
}
.SidebarSubNavBack_extendedSideBarBackIconLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  font-size: 20px;
  color:#3F3F3F;
}
.SidebarSubNavBack_extendedSideBarBackIcon {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  color:#3F3F3F;
}
.SidebarSubNavBack_extendedSideBarBackTitle {
  font-size:14px;
  font-weight:bold;
  color:#3F3F3F;
}

.SidebarSubNavBack_iconPosition {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.SidebarSubNav_panel {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
  flex-grow: 1;
}
.SidebarSubNav_navigation {
  align-items: stretch;
  /*background: rgb(241, 243, 246);*/
  background-color:#fefefe;
  min-height: 90vh;               /*Added on 20/07/19 to fix short nav bar */
}
.SidebarSubNav_navigation_narrow {
  width:72px;
}










.SidebarSubNav_iconContainerAll {
  border-bottom: 1px solid #E7E9E9;
}

.SidebarSubNav_iconContainerOpened {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 27px;
  width: calc(250px - 27px);    /*223*/
}

.SidebarSubNav_iconContainerClosed {
  /*: 72px;*/
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 27px;
  width: calc(72px - 27px);    /*45*/
}

.SidebarSubNav_iconItem {
  cursor: pointer;
  width: inherit;
}

.SidebarSubNav_NavIcon {
  font-size: 20px;
}

.hide {
  display:none;
}

.SidebarSubNav_foldContent {
  align-items: stretch;
  flex-grow:1;
  background-color: inherit;
  /*border: 2px solid rgb(241, 243, 246);*/
  padding: 20px;
  max-width: 100%;  /*NEW GRAPH FIXES*/
  min-width: 0;     /*NEW GRAPH FIXES*/
}

.scrollbarsStyle {
  background-color: '#a7b1c2';
  width: '5px';
  opacity: '0.5';
}