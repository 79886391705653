/**** SHC SUB HEADER ****/



.shcSubHeader_childSidebarMain {
  position: relative;
  width: 240px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100vh;
  display: flex;
  z-index: 1200;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
  background-color: #343A40;

  padding-top: 40px;
}
.shcSubHeader_childSidebarSmall {
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

@media (max-width: 659px) {
  .shcSubHeader_childSidebarPermanent {
    display:none;
  }
  .shcSubHeader_childSidebarTemporary {
    display:flex;
  }

  .shcSubHeader_childSidebarPermanentOpen {
    width:240px;
  }
  .shcSubHeader_childSidebarPermanentClose {
    width:72px;
  }

  .shcSubHeader_childSidebarTemporaryOpen {
    width:240px;
  }
  .shcSubHeader_childSidebarTemporaryClose {
    width:0;
  }

}
@media (min-width: 660px) {
  .shcSubHeader_childSidebarPermanent {
    display:flex;
  }
  .shcSubHeader_childSidebarTemporary {
    display:none;
  }

  .shcSubHeader_childSidebarPermanentOpen {
    width:240px;
  }
  .shcSubHeader_childSidebarPermanentClose {
    width:72px;
  }

  .shcSubHeader_childSidebarTemporaryOpen {
    width:240px;
  }
  .shcSubHeader_childSidebarTemporaryClose {
    width:72px;
  }
}

.shcSubHeaderAppbarContainer {
  width:100%;
}

.shcSubHeaderAppbarContainerMarginTop {
  margin-top: 45px;
}

.shcSubHeader_appbarContainer {
  display: flex;
  align-items: center;
  padding: 0;
  height:45px;
  padding-left: 20px;
}

.shcSubHeader_AppBar {

  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #2980b9;

  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  min-height: 45px;
}

.shcSubHeader_iconContainer {
  width:20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.shcSubHeader_NavIcon {
  font-size: 20px;
}
.shcSubHeader_Title {
  font-size: 20px;
}







