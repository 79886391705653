
.AuthLoginOptionsCard_parentContainer {
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
  min-height: 51px;
}

.AuthLoginOptionsCard_dropdownContainer {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-bottom: 10px;
  min-height: 51px;
}

.AuthLoginOptionsCard_container {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  /*margin-bottom: 10px;*/
  min-height: 51px;
}

.AuthLoginOptionsCard_containerStatusActive {
  width: 60px;
  font-size: 22px;
  background-color: #16a085;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.AuthLoginOptionsCard_containerStatusSetup {
  width: 60px;
  font-size: 22px;
  background-color: #e67e22;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.AuthLoginOptionsCard_containerContent {
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;
}
.AuthLoginOptionsCard_containerTypeIcon {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.AuthLoginOptionsCard_containerPrimaryContent {
  flex-grow: 1;
  padding-right: 10px;
  align-items: center;
}
.AuthLoginOptionsCard_containerPrimaryContentSecondaryText {
  color: #888;
}



.AuthLoginOptionsCard_containerContentStatus {
  flex-grow: 1;
  font-size: 14px;
}

.AuthLoginOptionsCard_containerContentRemoval {
  padding-right: 10px;
  cursor: pointer;
}

.AuthLoginOptionsCard_containerPrimaryContent {
  flex-grow: 1;
  padding-right: 10px;
  font-size: 16px;
}

.authenticatorFactorSetupAlready {
  background-color: #16a085;
  padding: 5px;
  color: #fff;
}


/******/
.AuthLoginSelectionCard_container {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-bottom: 10px;
}

.AuthLoginSelectionCard_containerContent {
  padding: 4px;
  padding-left: 16px;
  flex-grow: 1;
  min-height: 48px;
}
.AuthLoginSelectionCard_containerTypeIcon {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.AuthLoginSelectionCard_containerPrimaryContent {
  flex-grow: 1;
  padding-right: 10px;
  align-items: center;
}
.AuthLoginSelectionCard_containerPrimaryContentSecondaryText {
  color: #888;
}

.AuthenticatorAppContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-top: 10px;
  max-width: 800px;
  
  min-height: 150px;
  display: flex;
}

.AuthenticatorAppContainer_ExpandContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
}














.ShcModulesIM2ViewContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
  margin-top: 10px;
  max-width: 800px;
  
  min-height: 150px;
  display: flex;
}
.ShcModulesIM2ExpandContainerHeader {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eaeded;
}


.ShcModulesIdentityManagementViewContainerBody {
  padding: 0 20px 20px 20px;
  
  background-color: #fafafa;
}

.ShcModulesIdentityManagementExpandLeftBorderHightlight {
  border-left: 8px solid #444;
}

.ShcModulesIdentityManagementPrimaryLeftBorder_Verified {
  border-left: 8px solid #16a085;
}
.ShcModulesIdentityManagementPrimaryLeftBorder_ActionRequired {
  border-left: 8px solid #d35400;
}
.ShcModulesIdentityManagementPrimaryLeftBorder_Issues {
  border-left: 8px solid #c0392b;
}

.ShcModulesIdentityManagementPrimaryLeftBorder_Blank {
  border-left: 8px solid #fff;
}



.ShcModulesIdentityManagementViewExpiryNotSet_border {
  /*border-top:3px solid #16a085;*/
  border-left: 8px solid #16a085;
}
.ShcModulesIdentityManagementViewExpirySet_border {
  /*border-top:3px solid #c0392b;*/
  border-left: 8px solid #c0392b;
}

.ShcModulesIdentityManagementViewExpirySet_panel {
  /*border-top:3px solid #c0392b;*/
  background-color: #c0392b;
  color: #fff;
  padding-left:10px;
}

.ShcModulesIdentityManagementViewSummaryDetails {
  background-color: #fafafa;
  padding: 5px;
  border: 1px solid #ddd;
}




.ShcModulesIdentityManagementExpandTabbedContainer {
  display: flex;
  flex-grow: 1;
  /*background-color: #444;*/
  color: #fff;
  /*border-bottom: 1px solid #fff;*/
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  min-height: 60px;
  margin: 1px;
}

.ShcModulesIdentityManagementExpandTabbedOption {
  display: flex;
  flex-grow: 1;
  background-color: #444;
  justify-content: center;
  /*border: 1px solid #fff;*/
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1px;

  min-height: 20px;
  border-top: 8px solid #444;
  border-bottom: 8px solid #444;
}

.ShcModulesIdentityManagementExpandTabbedOptionSelected {
  display: flex;
  flex-grow: 1;
  background-color: #444;
  justify-content: center;
  /*border: 1px solid #fff;*/
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1px;

  min-height: 20px;
  border-top: 8px solid #444;
  border-bottom: 8px solid #2980b9;
}




.businessVerificationPricingApplies {
  padding-left: 20px;
  align-items: center;
  font-size: 14px;
  color: #8e44ad;
}







