

/**** FILE UPLOADS - CUSTOM ****/

input[name="s3FileUploader"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}






/**** LOCATION INPUT AND DROPDOWN ****/

.ShcLocationSearchInputDropdownStyle {
  border: 1px solid #545b64;
  padding-left: 10px;
}




/**** TOGGLE OPTIONS INPUT ****/

.ShcToggleOptionCard_toggleText {
  padding-left:10px;
  padding-right:20px;
}


/**** CHECKBOX OPTIONS INPUT ****/

.ShcCheckboxOptionCard_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
  padding-bottom:8px;
}
.ShcCheckboxOptionCard_checkboxText {
  font-size: 0.85rem;
  color: #545b64;
}

.ShcCheckboxOptionCard_selectedHighlight {
  border-color:#2980b9;
}

/**** RADIO OPTIONS INPUT ****/
.ShcSingleLineCardItem {
  padding: 0;
}

.ShcRadioOptionCard_primaryText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #545b64;
  font-size: 0.95rem;
  line-height: 1rem;
  display: inline-block;
  padding-bottom:8px;
  padding-right:25px; /*New to stop overlay of button on text*/
}
.ShcRadioOptionCard_secondaryText {
  font-size: 0.85rem;
  line-height: 0.7rem;
  color: #879596;
  margin-bottom: 10px;
}

.ShcRadioOptionCard_selectedHighlight {
  border-color:#2980b9;
}

.ShcRadioOptionCard_selectIcon {
  position: absolute;
  right: 0px;
  color: #879596;
  cursor: pointer;
}



/**** IDENTITY VIEW ****/

.ShcIdentityView_Section {
  padding: 0;
}

.ShcIdentityViewOnlyField {
  /*padding: 0rem 1rem;*/
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  /*border: 1px solid #aab7b8;*/
  outline: none;
  font-size: 0.9rem;
}


/**** IDENTITY INPUT ****/

.ShcIdentityInput_textNoInput {
  min-height: 25px;
  padding-left: 5px;  /*Was 35*/
  padding-top: 2px;
  border: 1px solid #eee;
  cursor: default;
  background-image: none !important;    /*Stop other icons overlaying on the screen*/
}


.ShcIdentityInput_inputSection {
  padding: 5px 4px 5px 4px;
}

.ShcIdentityInput_textInput_root {
  position: relative;
}
.ShcIdentityInput_textInput_child {
  width: 100%;
}

.ShcIdentityInput_textInput {
  padding: 0.4rem 1rem;
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #aab7b8;
  outline: none;
  font-size: 1.1rem;
  line-height: 1.9rem;
  height: 1.9rem;
  background-image: none !important;    /*Stop other icons overlaying on the screen*/
}

.ShcIdentityInput_textInput_iconPaddingLeft {
  padding-left: 30px;
}

.ShcIdentityInput_textInput_errors {
  border: 2px solid #c0392b;
}

.ShcIdentityInput_textInput_errors_icon::after {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  position: absolute;
  top: 6px;
  right: 8px;
  font-weight: 900;
  color: #c0392b;
}

.ShcIdentityInput_textInput_notificationsEnabled_icon::before {
  font-family: "Font Awesome 5 Free";
  content: "\f0f3";
  position: absolute;
  top: 6px;
  left: 8px;
  font-weight: 900;
  color: #2980b9;
  padding-left: 3px;
}
.ShcIdentityInput_textInput_notificationsDisabled_icon::before {
  font-family: "Font Awesome 5 Free";
  font-weight: lighter;
  content: "\f1f6";
  position: absolute;
  top: 6px;
  left: 8px;
  font-weight: 900;
  color: #7f8c8d;
}
.ShcIdentityInput_textInput_notificationsClickOverlayRelative {
  position: relative;
  height:0;
  width:0;
}
.ShcIdentityInput_textInput_notificationsClickOverlay {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 24px;
}


.ShcIdentityInput_helpText {
  font-size: 0.8rem;
  color: #879596;
  margin-bottom: 4px;
  margin-right: 4px;
}

.ShcIdentityInput_verifiedText {
  color: #16a085;
  font-size: 0.8rem;
  padding: 0px 8px;
  align-self: center;
}
.ShcIdentityInput_notVerifiedText {
  color: #c0392b;
  font-size: 0.8rem;
  padding: 0px 8px;
  align-self: center;
}
.ShcIdentityInput_reVerifyText {
  color: #e67e22;
  font-size: 0.8rem;
  padding: 0px 8px;
  align-self: center;
}
.ShcIdentityInput_verifiedIcon {
  color: #16a085;
  font-size: 18px;
}
.ShcIdentityInput_notVerifiedIcon {
  color: #c0392b;
  font-size: 18px;
}
.ShcIdentityInput_reVerifyIcon {
  color: #e67e22;
  font-size: 18px;
}
.ShcIdentityInput_shieldIcon {
  color: #3498db;
  font-size: 18px;
}

.ShcIdentityInput_VerifyIcon {
  color: #8e44ad;
  font-size: 18px;
}


.ShcIdentityInput_ContentIcon {
  color: #3498db;
  font-size: 18px;
}

.ShcIdentityInput_DisplayTextBox {
  min-height: 25px;
}

.ShcIdentityInput_LightGrey {
  color: #879596;
}



.ShcIdentityInput_notificationsEnabledIcon {
  color: #3498db;
  font-size: 18px;
}
.ShcIdentityInput_notificationsDisabledIcon {
  color: #7f8c8d;
  font-size: 18px;
}

.ShcIdentityInput_popupContainer {
  padding: 10px 10px;
}
.ShcIdentityInput_popupContainerFooter {
  padding: 0px 10px;
}

.ShcIdentityInput_popupBorder {
  border-top:1px solid #bbb;
  padding-top: 10px;
}
.ShcIdentityInput_popupBorderFooter {
  border-top:1px solid #bbb;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ShcIdentityInput_popupSecurityBox {
  border: 1px solid #ccc;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 5px;
}

.ShcIdentityInput_expandedContent {
  border: 1px solid #eaeded;
  padding: 4px 3px 15px 3px;  /*minus border from parent container*/
  background: #fafafa;
}


.ShcIdentityInput_dropdownContainer {
  padding: 10px 10px;
  border-top: 1px solid #ddd;
}
.ShcIdentityInput_dropdownContainerFooter {
  padding: 0px 10px;
}

.ShcIdentityInput_dropdownBorder {
  border-top:1px solid #bbb;
  padding-top: 10px;
}
.ShcIdentityInput_dropdownBorderFooter {
  border-top:1px solid #bbb;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ShcIdentityInput_dropdownSecurityBox {
  border: 1px solid #ccc;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 5px;
  background-color: #fff;
}




/**** INPUT CARDS ****/

.ShcInput {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
  border-top: 1px solid #eaeded;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
}

.ShcInput_textinputWrapper {
  width:100%;
  display:inline-block;
  position: relative;
}
.ShcInput_textinputWrapper::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f274';
  position: absolute;
  right: 6px;
  top: 20%;
}






.ShcInput_textInput_root {
  position: relative;
}
.ShcInput_textInput_child {
  width: 100%;
}

.ShcInput_textInput {
  padding: 0.4rem 1rem;
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #aab7b8;
  outline:none;
  font-size: 1.1rem;
  line-height: 1.9rem;
  height: 1.9rem;
}

.ShcInput_textareaInput {
  padding: 0.4rem 1rem;
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #aab7b8;
  outline:none;
  font-size: 1.1rem;
  line-height: 1.9rem;
}


.ShcInput_textInput_errors {
  border: 2px solid #c0392b;
}
/*
.ShcInput_textInput_errors::after {
  position: absolute;
  width: 3rem;
  font-size: 2rem;
  -webkit-transition: color .2s;
  transition: color .2s;
  top: .5rem;
}

.ShcInput_textInput_errors .suffix.right {
  right: 0
}
*/

.ShcInput_textInput_errors_icon::after {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  position: absolute;
  top: 6px;
  right: 8px;
  font-weight: 900;
  color: #c0392b;
}

.ShcInput_textInput_apiDataSaving_icon::after {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  position: absolute;
  top: 6px;
  right: 8px;
  font-weight: 900;
  color: #c0392b;
}
.ShcInput_textInput_apiDataSaving_notificationsClickOverlayRelative {
  position: relative;
  height:0;
  width:0;
}
.ShcInput_textInput_apiDataSaving_notificationsClickOverlay {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 24px;
}

/**** END INPUT CARDS ****/



/**** TEXT CONTAINER LABEL ****/

.ShcInput_textContainerLabel_root {
  position: relative;
}
.ShcInput_textContainerLabel_child {
  width: 100%;
}
.ShcInput_textContainerLabel_icon_copy::after {
  font-family: "Font Awesome 5 Free";
  content: "\f0c5";
  position: absolute;
  top: 6px;
  right: 8px;
  font-weight: 500;
  color: #444;
}
.ShcInput_textContainerLabel_textNoInput {
  min-height: 25px;
  padding-left: 35px;
  padding-top: 2px;
  border: 1px solid #eee;
  cursor: pointer;
  background-image: none !important;    /*Stop other icons overlaying on the screen*/
}

/**** RADIO OPTION ITEM ****/

.ShcRadioOptionItem_selectIcon {
  right: 0px;
  color: #879596;
  cursor: pointer;
}
.ShcRadioOptionItem_headlineText {
  font-size: 14px;
}
.ShcRadioOptionItem_secondaryText {
  color: #545b64;
  font-size: 13px;
}



/****************************************
*****INPUT TEXT V6**********************/
.inputTextV6_container {

}
.inputTextV6_primaryInputContainer {
  display: flex;
  flex-grow: 1;
  min-height: 32px;
}
.inputTextV6_primaryTextInputContainer {
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #aab7b8;
  padding: 0 10px;
  font-size: 1.1rem;
}
.inputTextV6_primaryAdditionalControlsContainer {
  background-color: #ebedec;
  min-height: 30px; 
  border-bottom: 1px solid #aab7b8;
  border-right: 1px solid #aab7b8;
  border-left: 1px solid #aab7b8;
}
.inputTextV6_postAdditionalControlsContainerOpen {
  border-top: 1px solid #aab7b8;
  border-right: 1px solid #aab7b8;
  background-color: #ebedec;
}
.inputTextV6_secondaryInfoContainer {

}
.inputTextV6_secondaryInfoIconItem {
  padding: 0 10px;
}

    



.inputTextV6_postAdditionalControlsContainer {
  cursor: pointer;
  align-self: center;
  min-height: 32px;
  min-width: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputTextV6_primaryInput {
  flex-grow: 1;
}
.inputTextV6_preInput {
  min-width:24px;
  padding-right: 10px;   
  color: #444;
  font-size: 14px;
  align-self: center;
}
.inputTextV6_postInput {
  padding-left: 10px;  
  color: #444;
  cursor: pointer;
  font-size: 14px;
  align-self: center;
}
.inputTextV6_textInputAsTextBox {
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 1.1rem;
  line-height: 1.9rem;
  height: 1.9rem;
}
.inputTextV6_primaryInputAsText {
  padding-left:2px;
  color: #16191f;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 1.1rem;
  line-height: 1.9rem;
  height: 1.9rem;

}